import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import LoggedOut from "../../Layouts/LoggedOut";
import { GoogleLogin } from 'react-google-login';
import { AuthService } from '../../services/auth';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useSpinner } from '../../contexts/LoadSpinner';


export default function SignUp() {
	const auth = useAuth();
    const { showSpinner, hideSpinner } = useSpinner();
	const navigate = useNavigate();

	const [ error, setError ] = useState('\t');

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		if (data.get('password') !== data.get('confirm-password')) {
			setError('As senhas digitadas não conferem');
			return;
		}

		const splitedName = data.get('name')?.trim().split(' ');
		if (splitedName.length <= 1) {
			setError('É necessário usar pelo menos dois nomes');
			return;
		}
		const firstName = splitedName.filter((a, i) => i !== splitedName.length - 1).join(' ');
		const lastName = splitedName[splitedName.length - 1];

		try {
			showSpinner('Preparando o ambiente para seu primeiro acesso...');
			const response = await AuthService.register({
				email: data.get('email'),
				password: data.get('password'),
				firstName,
				lastName,
			});
			auth.makeLogin(response.data.access);
		} catch(err) {
			setError(err.response?.data?.message);
			hideSpinner();
		};
	};

	const loginSocial = async (mechanism, token, avatar) => {
		try {
			const { data, status } = await AuthService.login({
				socialLoginToken: token,
				socialLogin: mechanism,
				username: 'social',
				password: 'social',
				avatar,
			});
			auth.makeLogin(data.access);
		} catch(err) {
			console.log(err.response.data);
		}
	}

	return (
		<LoggedOut
			title="Cadastro"
			handleSubmit={handleSubmit}
		>
			<Grid container>
				<Grid item sx={{
					width: '100%',
					margin: '15px 0 20px',
					display: 'flex',
					justifyContent: 'center'
				}}>
					<GoogleLogin
						clientId="991878789804-fv60l410um4qlctpmdnqjsjf0oh6s1rq.apps.googleusercontent.com"
						buttonText="Continuar com o Google"
						onSuccess={(r) => {
							console.log(r);
							loginSocial('google', r.tokenId, r.profileObj?.imageUrl);
						}}
						onFailure={(r) => {console.log(r)}}
						cookiePolicy={'single_host_origin'}
					/>
				</Grid>
			</Grid>
			<Typography variant='body1' align='center'>
				OU
			</Typography>
			<TextField
				margin="normal"
				required
				fullWidth
				id="name"
				label="Nome completo"
				name="name"
				autoFocus
				type={'email'}
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				id="email"
				label="E-mail"
				name="email"
				autoComplete="email"
				autoFocus
				type={'email'}
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="password"
				label="Senha"
				type="password"
				id="password"
				autoComplete="current-password"
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="confirm-password"
				label="Confirmar senha"
				type="password"
				id="confirm-password"
			/>
			<Typography variant={'caption'} color={'error'}>
				{error}
			</Typography>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
			>
				Cadastrar
			</Button>
			<Grid container>
				<Grid item>
					<Link href="/login" variant="body2">
						{"Já tem um cadastro? Faça login"}
					</Link>
				</Grid>
			</Grid>
		</LoggedOut>
	)
}