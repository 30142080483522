import { useLocation, useNavigate } from 'react-router';

import { Button, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

import { useEffect, useMemo, useState } from 'react';
import FlagIcon from '@mui/icons-material/Flag';
import { hashPath } from '../../../constants';
import { HandleGoal } from './handle';
import { GoalAdvance } from './advance';


export const GoalsComponent = ({ goals, ids }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [hash, hashTargetId] = useMemo(() => {
        const splited = location.hash?.split('?');
        return [splited[0] ?? '', splited[1] ?? null];
    }, [location.hash]);

    const [handledGoal, setHandledGoal] = useState(null);
    const [handledAnswers, setHandledAnswers] = useState(null);

    const closeHandledGoal = () => {
        if (Object.values(hashPath.goals).includes(hash)) {
            navigate(location.pathname);
        } else {
            setHandledGoal(null);
        }
    }

    const closeHandledAnswers = () => {
        if (Object.values(hashPath.goals).includes(hash)) {
            navigate(location.pathname);
        } else {
            setHandledAnswers(null);
        }
    }

    useEffect(() => {
        if (hash === hashPath.goals.update) {
            const goal = goals?.find(n => n.id.toString() === hashTargetId);
            if (goal) {
                setHandledGoal(goal);
            }
        } else if (hash === hashPath.goals.create) {
            if (hashTargetId === ids.habitId.toString()) {
                setHandledGoal({
                    ...ids,
                    kind: 'start'
                });
            }
        } else if (hash === hashPath.goals.putAnswer) {
            const goal = goals?.find(n => n.id.toString() === hashTargetId);
            if (goal) {
                setHandledAnswers({
                    goal,
                    answers: goal.answers ?? [],
                    kind: 'putAnswer'
                });
            }
        } else if (hash === hashPath.goals.myAdvance) {
            const goal = goals?.find(n => n.id.toString() === hashTargetId);
            if (goal) {
                setHandledAnswers({
                    goal,
                    answers: goal.answers,
                    kind: 'myAdvance'
                });
            }
        } else {
            closeHandledGoal();
            closeHandledAnswers();
        }
    }, [hash, hashTargetId, location]);

    return (
        <Box>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="div">
                        Metas
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1, width: '40%', mr: 3 }} />
                    <Grid container spacing={5}>
                        {goals?.map((goal, index) => {
                            return (
                                <Grid item key={`${goal.id}-${index}`} xs={12} sm={6} md={4}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography variant='h6'>{goal.name}</Typography>
                                            <Divider sx={{ width: '30%', mb: 1 }} />
                                            <Typography variant='body1'>{goal.description}</Typography>
                                        </CardContent>
                                        <CardActions sx={{ flexDirection: 'column' }}>
                                            <Stack
                                                sx={{ pt: 2 }}
                                                direction="row"
                                                spacing={3}
                                                justifyContent="center"
                                            >
                                                <Tooltip title={`Modificar meta`}>
                                                    <IconButton
                                                        onClick={() => {
                                                            navigate(`${hashPath.goals.update}?${goal.id}`);
                                                        }}
                                                        color={'secondary'}
                                                        variant='contained'
                                                        size={'small'}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {Boolean(goal?.regularity) && <Tooltip title={`Enviar resposta`}>
                                                    <IconButton
                                                        onClick={() => {
                                                            navigate(`${hashPath.goals.putAnswer}?${goal.id}`);
                                                        }}
                                                        color={'secondary'}
                                                        variant='contained'
                                                        size={'small'}
                                                    >
                                                        <QuestionAnswerIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Stack>
                                            <Button
                                                sx={{ my: 1 }}
                                                fullWidth
                                                variant={'contained'}
                                                size={'small'}
                                                startIcon={<AirlineStopsIcon />}
                                                onClick={() => {
                                                    navigate(`${hashPath.goals.myAdvance}?${goal.id}`);
                                                }}
                                            >
                                                Meu Avanço
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
                <CardActions sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                }}>
                    <Button
                        startIcon={<FlagIcon />}
                        size="small"
                        variant={'contained'}
                        onClick={() => {
                            navigate(`${hashPath.goals.create}?${ids.habitId}`);
                        }}
                    >Nova meta</Button>
                </CardActions>
            </Card>
            <HandleGoal goal={handledGoal} onClose={closeHandledGoal} />
            <GoalAdvance data={handledAnswers} onClose={closeHandledAnswers} />
        </Box>
    );
}
