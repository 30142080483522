import React, { useEffect, useRef, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Typography } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import api from "../../services/api";
import { FRONTEND_URL } from "../../constants";
import { useNotifications } from "../../contexts/notification";


export const ShareBox = ({ objectToShare, onClose }) => {
    const { showSnack } = useNotifications();
    const [link, setLink] = useState('');
    const buttonRef = useRef(null);

    const fetchLink = async () => {
        const { data } = await api.post('/links', {
            kind: objectToShare?.kind,
            spaceId: objectToShare?.spaceId,
            setId: objectToShare?.setId,
        });
        const _lind = `
            ${FRONTEND_URL}/links/import?l=${data.id}&k=${data.secretKey}
        `.trim();
        setLink(_lind);
        return _lind;
    }

    useEffect(() => {
        if (objectToShare) {
            fetchLink();
        }
    }, [objectToShare]);

    useEffect(() => {
        if (link.length > 0) {
            buttonRef?.current?.click();
        }
    }, [ link ]);

    return (
        <Dialog
            open={Boolean(objectToShare)}
            onClose={() => onClose?.()}
            PaperProps={{
                sx: {
                    bgcolor: 'background.paper'
                }
            }}
            aria-labelledby="sharebox-dialog"
            aria-describedby="share-box-description"

        >
            <DialogTitle id="sharebox-dialog">
                Compartilhar "{objectToShare?.title}"
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="share-box-description">
                    <Typography variant="body1" component={'p'}>
                        Compartilhe este link com a pessoa para quem você deseja
                        enviar est{objectToShare?.kind === 'space' ? 'e espaçp' : 'a lista'}.
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 2,
                        mb: 0,
                    }}>
                        <CopyToClipboard
                            text={link}
                            onCopy={() => {
                                showSnack('Link copiado');
                            }}
                        >
                            <Button
                                disabled={link.length <= 0}
                                startIcon={<ContentCopyIcon />}
                                ref={buttonRef}
                                variant="contained"
                            >Copiar link</Button>
                        </CopyToClipboard>
                    </Box>

                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ flexDirection: 'column', mt: 1, alignItems: 'flex-end' }}>
                <Button
                    autoFocus
                    onClick={() => {
                        onClose?.();
                    }} variant={'text'}
                >Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}
