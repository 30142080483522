import api from "./api";

export class SpacesService {
    static async list() {
        return api.get('/spaces');
    }

    static async updateOrCreate(space) {
        if (space.id) {
            return api.put(`/spaces`, space);
        }
        return api.post(`/spaces`, space);
    }

    static async deleteSpace(spaceId) {
        return api.delete(`/spaces/${spaceId}`);
    }
}