import axios from 'axios';
import { getToken, isAuthenticated, logout } from './auth';

const api = axios.create({
    baseURL: 'https://api.meushabitos.com/habits',
    // baseURL: 'http://localhost:3333/habits',
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(function (response) {
    return response;
}, (error) => {
    console.log(error.response.status, isAuthenticated())
    if (error.response && error.response.status === 401 && isAuthenticated()) {
        logout();
    }
    return Promise.reject(error);
});

export default api;
