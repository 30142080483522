import api from "./api";
const PREFIX = '/goals';

export class GoalsService {
    static async updateOrCreate(goal) {
        if (goal.id) {
            return api.put(PREFIX, goal);
        }
        return api.post(PREFIX, goal);
    }

    static async putAnswer(data) {
        return api.put(`${PREFIX}/answer`, data);
    }

    static async listAnswers(goalId) {
        return api.get(`${PREFIX}/answer/${goalId}`);
    }

    static async delete(goalId) {
        return api.delete(`${PREFIX}/${goalId}`);
    }
}