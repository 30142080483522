import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router';

import { useSpinner } from "../../contexts/LoadSpinner"
import { useNotifications } from "../../contexts/notification";
import api from "../../services/api";
import { useSpaces } from "../../contexts/spaces";

export const ImportLinkPage = () => {
    const location = useLocation();

	const  [ linkId, secretKey, isSet ] = useMemo(() => {
		const urlParams = new URLSearchParams(location.search);
		return [urlParams.get('l'),urlParams.get('k'), Boolean(urlParams.get('s'))];
	});
    const navigate = useNavigate();
    const { updateSpaces, spaces } = useSpaces();
    const { showSnack } = useNotifications();
    const { showSpinner, hideSpinner } = useSpinner();
    let [ lock, setLock ] = useState(false);

    const importLink = async () => {
        lock = true;
        try {
            const {data} = await api.post('/links/apply', {
                linkId, secretKey,
                pushToSpace: spaces[0].id,
            });
            if (data.kind === 'space') {
                updateSpaces(data.spaceId);
            } else if (data.kind === 'set') {
                updateSpaces(data.setId, 'set', spaces[0].id);
            }
        } catch(err) {
            showSnack(err.response?.data?.message, 'error');
            console.error(err);
            hideSpinner();
            navigate('/meus-espacos');
        }
    }

    useEffect(() => {
        if (spaces.length > 0) {
            if (linkId && secretKey) {
                showSpinner(
                    'Importando informações do link...',
                );
                if (!lock) {
                    setLock(true);
                    importLink();
                }
            } else {
                navigate('/meus-espacos');
            }
        }
    }, [ spaces ]);
}