import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import AddRoadIcon from '@mui/icons-material/AddRoad';

import { useSpaces } from '../../contexts/spaces';
import { RANDOM_IMAGE_URL, hashPath } from '../../constants';
import { MyHeader } from '../../components/Header';
import { SetsService } from '../../services/sets';
import { useNotifications } from '../../contexts/notification';
import HandleSets from './handleSets';
import { HabitsService } from '../../services/habits';
import HandleHabits from './handleHabits';
import { ShareBox } from '../../components/ShareBox';
import { NotesComponent } from './Notes';
import { SchedulesComponent } from './Schedules';
import { MyFooter } from '../../components/Footer';
import { GoalsComponent } from './Goals';



export default function SetsPage(props) {
	const location = useLocation();
	const navigate = useNavigate();
    const [hash, hashTargetId] = useMemo(() => {
        const splited = location.hash?.split('?');
        return  [splited[0] ?? '', splited[1] ?? null];
    });

	const [sharedObject, setSharedObject] = useState(null);

	const queryHabit = useMemo(() => {
		const urlParams = new URLSearchParams(location.search);
		return urlParams.get('h');
	});

	const {
		showDialog,
		closeDialog,
	} = useNotifications();
	const { setId, spaceId } = useParams();
	const { spaces, updateHabit, updateSet } = useSpaces();
	const [handleSets, setHandleSets] = useState({
		open: false,
		onClose: () => { },
		set: {},
	});
	const closeHandleSets = () => {
		if (Object.values(hashPath.sets).includes(hash)) {
			navigate(location.pathname);
		} else if (hash === '') {
			closeDialog();

			setHandleSets({
				open: false,
				onClose: closeHandleSets,
				set: {},
			});
		}
	}
	const [handleHabits, setHandleHabits] = useState({
		open: false,
		onClose: () => { },
		habit: {},
	});
	const closeHandleHabits = () => {
		if (Object.values(hashPath.habits).includes(hash)) {
			navigate(location.pathname);
		} else if (hash === '') {
			closeDialog();

			setHandleHabits({
				open: false,
				onClose: closeHandleHabits,
				habit: {},
			});
		}
	}

	const selectedSet = useMemo(
		() => {
			const space = spaces.find(s => s.id.toString() === spaceId);
			if (space) {
				const set = space.sets.find(s => s.id.toString() === setId);
				if (set) {
					return set;
				}
			}
			return {
				name: '',
				description: '',
				image: 'random',
				habits: []
			}
		}, [spaces, setId, spaceId]
	)

	useEffect(() => {
		if (selectedSet) {
			if (hash === hashPath.sets.update) {
				setHandleSets({
					open: true,
					onClose: closeHandleSets,
					set: selectedSet,
				});
			} else if (hash === hashPath.sets.delete) {
				showDialog({
					title: `Deletar permanentemente "${selectedSet.name}"?`,
					message: 'Esta ação deletará todas os hábitos, alarmes e anotações dentro desta lista. Esta ação é irreversível. Deseja realmente continuar?',
					mode: 'confirm',
					confirmButtonColor: 'error',
					confirmText: 'Sim, quero deletar',
					sendAction: async (r) => {
						if (r === 'confirm') {
							await SetsService.deleteSpace(selectedSet.id);
							updateSet('delete', selectedSet)
							navigate(`/meus-espacos/${selectedSet.spaceId}`);
						}
					}
				});
			} else if (hash === hashPath.habits.create) {
				setHandleHabits({
					open: true,
					onClose: closeHandleHabits,
					habit: {
						setId: selectedSet.id,
						spaceId: selectedSet.spaceId,
						id: 0
					},
				});
			} else if (hash === hashPath.habits.update) {
				const habit = selectedSet.habits?.find(h => h.id.toString() === hashTargetId);
				
				if (habit) {
					setHandleHabits({
						open: true,
						onClose: closeHandleHabits,
						habit: {
							...habit,
							setId: selectedSet.id,
							spaceId: selectedSet.spaceId,
						},
					});
				}
			} else if (hash === hashPath.habits.delete && hashTargetId) {
				const habit = selectedSet.habits?.find(h => h.id.toString() === hashTargetId);
				
				if (habit) {
					showDialog({
						title: `Deletar permanentemente "${habit.name}"?`,
						message: 'Esta ação também removerá todos os alarmes e anotações relacionados a este hábito. Esta ação é irreversível. Deseja realmente continuar?',
						mode: 'confirm',
						confirmButtonColor: 'error',
						confirmText: 'Sim, quero deletar',
						sendAction: async (r) => {
							if (r === 'confirm') {
								await HabitsService.deleteHabit(habit.id);
								updateHabit('delete', {
									id: habit.id,
									setId: selectedSet.id,
									spaceId: selectedSet.spaceId,
								});
								navigate(location.pathname);
							}
						}
					});
				}
			} else {
				closeHandleSets();
				closeHandleHabits();
			}
		}
	}, [location, hash, selectedSet]);


	return (
		<>
			<CssBaseline />
			<MyHeader
				title={selectedSet.name}
				backTo={`/meus-espacos/${selectedSet.spaceId}`}
				{...props}
			/>
			<main>
				<Box
					sx={{
						bgcolor: 'background.paper',
						pt: 10,
						pb: 6,
					}}
				>
					<Paper
						sx={{
							position: 'relative',
							backgroundColor: 'grey.800',
							color: '#fff',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundImage: `url(${selectedSet.image === 'random' ? RANDOM_IMAGE_URL : selectedSet.image})`,
						}}
					>
						<Box sx={{
							backgroundColor: 'rgba(0,0,0,.3)',
							position: 'absolute',
							top: 0,
							bottom: 0,
							right: 0,
							left: 0,
						}} />
						<Container maxWidth="sm" sx={{ position: 'relative', py: 5 }}>
							<Typography
								component="h1"
								variant="h2"
								align="center"
								gutterBottom
							>
								{selectedSet.name}
							</Typography>
							<Typography variant="h5" align="center" paragraph>
								<div className="content" dangerouslySetInnerHTML={{
									__html: selectedSet.description
								}} />
							</Typography>
							<Stack
								sx={{ pt: 4 }}
								direction="row"
								spacing={2}
								justifyContent="center"
							>
								<Tooltip title='Compartilhar lista'>
									<Button
										variant='contained'
										size={'small'}
										onClick={() => {
											setSharedObject({
												title: selectedSet.name,
												spaceId: selectedSet.spaceId,
												setId: selectedSet.id,
												kind: 'set'
											});
										}}
									><ShareIcon /></Button>
								</Tooltip>
								<Tooltip title='Modificar lista'>
									<Button onClick={() => {
										navigate(hashPath.sets.update);
									}} color={'secondary'} variant='contained' size={'small'}><EditIcon /></Button>
								</Tooltip>
								<Tooltip title='Deletar lista'>
									<Button onClick={() => {
										navigate(hashPath.sets.delete);
									}} color={'error'} variant='contained' size={'small'}><DeleteIcon /></Button>
								</Tooltip>
							</Stack>
						</Container>
					</Paper>
				</Box>
				<Container sx={{ py: 2 }} maxWidth="md">
					{/* End hero unit */}
					<Grid container spacing={4}>
						{selectedSet.habits?.map(habit =>
							<Grid item key={`${habit.id}-${habit.name}`} xs={12} md={12}>
								<Accordion
									defaultExpanded={
										Boolean(queryHabit) && parseInt(queryHabit) === habit.id
									}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={`${habit.id}-${habit.name}-id`}
										id={`${habit.id}-${habit.name}-id`}

									>
										<Typography>{habit.name}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											{habit.description}
										</Typography>
										<Stack
											sx={{
												mt: 3,
												mb: 0
											}}
											direction={{ xs: 'column', sm: 'row' }}
											spacing={2}
											justifyContent="center"
										>
											<Button
												onClick={() => {
													navigate(`${hashPath.habits.update}?${habit.id}`);
												}}
												size={'small'}
												variant={'contained'}
												color={'secondary'}
												startIcon={<EditIcon />}
											>Modificar Hábito</Button>
											<Button onClick={() => {
												navigate(`${hashPath.habits.delete}?${habit.id}`);
											}}
												size={'small'}
												variant={'contained'} color={'error'} startIcon={<DeleteIcon />}>Deletar Hábito</Button>
										</Stack>
										<GoalsComponent
											goals={habit?.goals?.map(n => {
												return {
													...n,
													spaceId: selectedSet.spaceId,
													setId: selectedSet.id,
													habitId: habit.id
												}
											})}
											ids={{
												spaceId: selectedSet.spaceId,
												setId: selectedSet.id,
												habitId: habit.id
											}}
										/>
										<NotesComponent ids={{
											spaceId: selectedSet.spaceId,
											setId: selectedSet.id,
											habitId: habit.id
										}} notes={habit.notes?.map(n => {
											return {
												...n,
												spaceId: selectedSet.spaceId,
												setId: selectedSet.id,
												habitId: habit.id
											}
										})} />
										<SchedulesComponent schedules={habit?.schedules ?? []} ids={{
											setId: selectedSet.id,
											spaceId: selectedSet.spaceId,
											habitId: habit.id,
										}} selectedSet={selectedSet} />
									</AccordionDetails>
								</Accordion>
							</Grid>
						)}
					</Grid>
					<Grid container spacing={4} sx={{
						justifyContent: 'center',
						alignItems: 'center',
					}}>
						<Grid item md={12} sx={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							mt: 3
						}}>
							<Button
								variant='contained'
								startIcon={<AddRoadIcon />}
								onClick={() => {
									navigate(hashPath.habits.create);
								}}
							>Adicionar Hábito</Button>
						</Grid>
					</Grid>
				</Container>
			</main>
			<MyFooter />
			<HandleSets {...handleSets} />
			<HandleHabits {...handleHabits} />
			<ShareBox objectToShare={sharedObject} onClose={() => { setSharedObject(null) }} />
		</>
	);
}