import { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, CssBaseline, Dialog, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { MyHeader } from "../../components/Header";
import { AuthManager } from "../../contexts/auth";
import styles from './styles.module.css'
import { UserService } from "../../services/user";
import { Buffer } from "buffer"
import { CloseRounded, Done, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";

export default function MyAccount() {
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser } = useContext(AuthManager)
  const [openModalImage, setOpenModalImage] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [emailEdit, setEmailEdit] = useState()
  const [nameEdit, setNameEdit] = useState()
  const [avatarEdit, setAvatarEdit] = useState()
  const [file, setFile] = useState(null)

  const saveChanges = async () => {
    setUser({
      ...user,
      avatar: file ? URL.createObjectURL(file) : user?.avatar,
      firstName: nameEdit,
      lastName: "",
      email: emailEdit,
    })
    setIsEditing(false)

    let publicURL

    if (file) {
      let extension = file.type.split('/')[1]
      let arrayBuffer = await file.arrayBuffer()
      let buffer = Buffer.from(arrayBuffer)
      let data = {
        case: "profile",
        extension
      }

      const links = await UserService.getLink(data)

      await UserService.uploadImage(links.data.uploadUrl, buffer, extension)
      publicURL = links.data.publicUrl
    }

    UserService.updateUser({
      avatar: file ? publicURL : user?.avatar,
      firstName: nameEdit,
      email: emailEdit,
      lastName: ""
    })
    enqueueSnackbar("Dados atualizados!", {
      variant: "success"
    })
  }

  useEffect(() => {
    if (user) {
      setEmailEdit(user.email)
      setNameEdit(user.firstName + user?.lastName ?? "")
    }
  }, [user])

  return <>
    <CssBaseline />
    <MyHeader title={'Minha conta'} backTo={'/meus-espacos'} />
    <main>
      <Container maxWidth="sm" className={styles.container}>
        <Grid container className={styles.content}>
          {isEditing
            ? <Tooltip title="Mudar imagem" placement={"top"} sx={{
              cursor: 'pointer'
            }}>
              <label htmlFor="avatar">
                <div className={styles.avatarEditable}>
                  <Avatar src={avatarEdit ?? user?.avatar} sx={{
                    width: 250,
                    height: 250
                  }} className={styles.avatar} />
                  <Edit className={styles.editIcon} />
                </div>
              </label>

              <input id="avatar" type="file" accept="image/*" hidden onChange={(e) => {
                if (e.target.files[0]) {
                  setFile(e.target.files[0])
                  setAvatarEdit(URL.createObjectURL(e.target.files[0]))
                }
              }} />
            </Tooltip> :
            <Tooltip title={user?.avatar ? 'Ver imagem' : ''} placement="top"
              onClick={() => {
                user?.avatar && setOpenModalImage(true)
              }}
            >
              <Avatar src={user?.avatar} sx={{
                width: 250,
                height: 250
              }} />
            </Tooltip>
          }

          <Grid container direction={"column"} alignItems={"center"}>
            {isEditing ?
              <input
                className={styles.inputEditableName}
                value={nameEdit}
                onChange={(e) => setNameEdit(e.target.value)}
              />
              : <Typography variant="h5">{user?.firstName} {user?.lastNmae ?? ""}</Typography>}
            {isEditing ?
              <input
                className={styles.inputEditableEmail}
                value={emailEdit}
                onChange={(e) => setEmailEdit(e.target.value)}
              />
              : <Typography variant="body1">{user?.email}</Typography>}

            <Grid item xs={12} marginTop={5}>
              {!isEditing && <Button variant="outlined" color="primary" endIcon={<Edit />} onClick={() => setIsEditing(true)}>
                Editar
              </Button>}
              {isEditing && <div className={styles.containerButtons}>
                <Button variant="contained" color="error" endIcon={<CloseRounded />} onClick={() => {
                  setIsEditing(false)
                  setEmailEdit(user?.email)
                  setNameEdit(user?.firstName)
                  setAvatarEdit(null)
                }}>
                  Cancelar
                </Button>
                <Button variant="contained" color="success" endIcon={<Done />} onClick={() => {
                  saveChanges()
                }}>
                  Salvar
                </Button>
              </div>}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main >
    <Dialog
      maxWidth={'sm'}
      open={openModalImage}
      onClose={() => setOpenModalImage(false)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',

        }
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(1.5px)'
        }
      }}>
      <Avatar src={user?.avatar} sx={{
        width: '400px',
        height: '400px',
        margin: '30px'
      }} className={styles.avatarModal}/>
    </Dialog>
  </>
}