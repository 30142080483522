import api from "./api";

export class HabitsService {
    static async updateOrCreate(habit) {
        if (habit.id) {
            return api.put(`/habits`, habit);
        }
        return api.post(`/habits`, habit);
    }

    static async deleteHabit(habitId) {
        return api.delete(`/habits/${habitId}`);
    }
}