import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDebounce } from 'use-debounce';

import { useSpaces } from '../../../contexts/spaces';
import { useNotifications } from '../../../contexts/notification';
import { NotesService } from '../../../services/notes';
import { isMobile } from '../../../constants';


export const HandleNotes = (props) => {
    const { updateNote } = useSpaces();

    const { showSnack } = useNotifications();

    const [action, setAction] = useState('view');
    const [content, setContent] = useState('');
    const [canSend, setCansend] = useState(true);
    const [debouncerValue] = useDebounce(content, 300);

    const [note, setNote] = useState(props.note);

    useEffect(() => {
        setNote(props.note);
    }, [props.note])

    useEffect(() => {
        if (note) {
            if (!note.stillEditing) {
                setAction(note.id ? 'view' : 'handle');
                setContent(note.content);
            }
        } else {
            setAction('view');
        }
    }, [note]);


    const handleSubmit = async (updateState = false) => {
        if (note) {
            const copiedNote = {...note};
            if (updateState) {
                props.onClose();
            }
            
            try {
                let body = {
                    content: content ?? '',
                    id: copiedNote.id > 0 ? copiedNote.id : undefined,
                    habitId: copiedNote.habitId,
                    spaceId: copiedNote.spaceId,
                    setId: copiedNote.setId,
                };

                if (!copiedNote.id) {
                    body.createdAt = new Date();

                    if (body.content === '') {
                        props.onClose();
                        return;
                    }
                }

                if (updateState && copiedNote.id && (!content || content === '')) {
                    await NotesService.deleteNote(copiedNote.id);
                    updateNote('delete', body);
                    return;
                }

                if (body.id) {
                    await NotesService.updateNote(body);
                } else if (body.content !== '') {
                    const response = await NotesService.createNote(body);
                    body.id = response.data.id;
                    setNote({
                        ...copiedNote,
                        content: body.content,
                        id: response.data.id,
                        stillEditing: true,
                    });
                    updateNote('create', body);
                } else {
                    props.onClose();
                    return;
                }
                if (updateState) {
                    updateNote(copiedNote.id ? 'update' : 'create', body);
                }
            } catch (err) {
                showSnack(err.message, 'error');
            } finally {
                setCansend(true);
            }
        }
    }

    useEffect(() => {
        if (canSend && note && typeof debouncerValue === 'string') {
            setCansend(false);
            handleSubmit();
        }
    }, [debouncerValue]);

    const actions = useMemo(() => ({
        handle:
            (<Box>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        disableNativeSpellChecker: false,
                        toolbar: {
                            items: [
                                'undo', 'redo',
                                '|', 'heading',
                                '|', 'bold', 'italic', 'subscript', 'link',
                                '|', 'bulletedList', 'numberedList', 'todoList'
                            ]
                        }
                    }}
                    data={content ?? ''}
                    onChange={(_, editor) => {
                        const data = editor.getData();
                        setContent(data ?? '');
                    }}
                />
            </Box>),
        view: (
            <div dangerouslySetInnerHTML={{
                __html: `<div class="view-note">${content}</div>`
            }} />
        )
    }), [note, content]);

    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile}
            open={Boolean(note)}
            onClose={action === 'view' ? props.onClose : () => {
                handleSubmit(true);
            }}
        >
            <DialogContent
                sx={{
                    minHeight: '50vh',
                }}
            >
                {Boolean(note) && actions[action]}
            </DialogContent>
            <DialogActions>
                <Stack
                    sx={{ pt: 4 }}
                    direction="row"
                    spacing={2}
                    width={'100%'}
                    justifyContent='space-between'
                >
                    <Tooltip title='Deletar anotação'>
                        <Button
                            color={'error'}
                            variant='contained'
                            size={'small'}
                            onClick={async () => {
                                if (note.id) {
                                    NotesService.deleteNote(note.id);
                                    updateNote('delete', note);
                                }
                                props.onClose();
                            }}
                        ><DeleteIcon /></Button>
                    </Tooltip>
                    <Box>
                        <Tooltip sx={{mr: 2}} title={`${action === 'view' ? 'Editar' : 'Visualizar'} anotação`}>
                            <Button onClick={() => {
                                setAction(action === 'view' ? 'handle' : 'view');
                            }} color={'secondary'} variant='contained' size={'small'}>
                                {action === 'view' ? <EditIcon /> : <RemoveRedEyeIcon />}
                            </Button>
                        </Tooltip>
                        <Tooltip title={'Fechar'}>
                            <Button onClick={action === 'view' ? props.onClose : () => {
                                handleSubmit(true);
                            }} color={'primary'}
                                variant='contained'
                                size={'small'}>
                                <CheckIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>

    );
}
