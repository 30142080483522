import React, { createContext, useContext, useState } from "react";
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import api from "../services/api";
import { Button } from "@mui/material";

export const NotificationsManager = createContext({});
export const useNotifications = () => useContext(NotificationsManager);

async function askUserPermission() {
    return Notification.requestPermission();
}

async function registerPushNotification(trier = 0) {
    const skipDevice = localStorage.getItem('dontSendNotificationsInThisDevice');
    if (Boolean(skipDevice)) {
        return;
    }

    if (Notification.permission === "granted") {
        const applicationServerKey =
            'BLhVgloFKsBFBgvcXaHvGJxxK67GUVf4n7Uy5VOnbssvXctY9dCNlFvbROebu42FGP9SiyBls4Ftrlo-bodbhx4'

        const options = { applicationServerKey, userVisibleOnly: true }
        const registration = await navigator.serviceWorker.ready;
        try {
            const subscription = await registration.pushManager.subscribe(options);
            const { data } = await api.post('/schedules/registry-notification', subscription);
            const deviceId = data.deviceId.toString();
            localStorage.setItem('deviceId', deviceId);
        } catch (err) {
            console.log(err);
            const waiter = new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, 5000);
            });
            await waiter;
            if (trier < 5) {
                return registerPushNotification(trier + 1);
            }
        }
        return null;
    }
}


export const NotificationsProvider = ({ children }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ dialog, setDialog ] = useState({
        open: false,
        mode: 'confirm',
        title: '',
        message: '',
        sendAction: () => {},
    });

    const closeDialog = (callerId) => {
        if (dialog.callerId && dialog.callerId !== callerId) {
            return;
        }
        setDialog({...dialog, callerId: undefined, open: false});
    }

    const showDialog = (config) => {
        setDialog({
            ...config,
            open: true,
        });
    }

    const showSnack = (text, variant = 'success') => {
        enqueueSnackbar(text, {
            variant,

        });
    };

    return (
        <NotificationsManager.Provider value={{
            askUserPermission,
            registerPushNotification,
            showSnack,
            showDialog,
            closeDialog,
        }}>
            <>
                {children}
                <Dialog
                    open={dialog.open}
                    PaperProps={{
                        sx: {
                            bgcolor: 'background.paper'
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialog.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{flexDirection: 'column',mt:1,alignItems:'flex-end'}}>
                        {dialog.mode === 'confirm' && <>
                            <Button onClick={() =>{
                                 closeDialog(dialog.callerId);
                                 dialog.sendAction('confirm');
                            }} autoFocus variant={'contained'} color={dialog.confirmButtonColor ?? 'primary'}>
                                ${dialog.confirmText ?? 'Confirmar'}
                            </Button>
                            <Button 
                                sx={{mt:0.5}}
                                onClick={() => {
                                    closeDialog(dialog.callerId);
                                    dialog.sendAction('cancel');
                               }}  variant={'text'} color={'secondary'}
                            >Cancelar</Button>
                        </>}
                        {dialog.mode === 'ok' && <>
                            <Button 
                                autoFocus
                                onClick={() => {
                                    closeDialog(dialog.callerId);
                                    dialog.sendAction?.();
                               }}  variant={'text'}
                            >Entendi</Button>
                        </>}
                    </DialogActions>
                </Dialog>
            </>
        </NotificationsManager.Provider>
    )
}
