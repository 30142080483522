import api from "./api";
const PREFIX = '/schedules';

export class SchedulesService {
    static async updateOrCreate(schedule) {
        if (schedule.id) {
            return api.put(PREFIX, schedule);
        }
        return api.post(PREFIX, schedule);
    }

    static async delete(scheduleId) {
        return api.delete(`${PREFIX}/${scheduleId}`);
    }
}