import api from "./api";

let block = false;
let lastId = 0;

export class NotesService {
    static async createNote({
        content,
        habitId
    }) {
        if (!block) {
            const res = await api.post(`/notes`, {
                content,
                habitId
            });
            lastId = res.data.id;
            block = true;
            setTimeout(() => {block = false}, 2000);
        }
        return {
            data: {id: lastId}
        }
    }

    static async updateNote({
        content,
        habitId,
        id
    }) {

        return api.put(`/notes`, {
            content,
            habitId,
            id
        });
    }

    static async deleteNote(id) {
        return api.delete(`/notes/${id}`);
    }
}