import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./auth";
import { SpacesService } from "../services/spaces";
import { useLocation, useNavigate } from "react-router";
import { useSpinner } from "./LoadSpinner";


export const SpacesManager = createContext({});
export const useSpaces = () => useContext(SpacesManager);


export const SpacesProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
	const  [nextUrl, nextUrlEncoded] = useMemo(() => {
		const urlParams = new URLSearchParams(location.search);
		const next = urlParams.get('next');
		if (next) {
			return [atob(next), next];
		}
		return [next, next];
	});
    const { showSpinner, hideSpinner, spinnerIsVisible } = useSpinner();

    const [ canFetch, setCanFetch ] = useState(true);

    const [spaceId, setSpaceId] = useState(0);

    const { isAuthenticated } = useAuth();
    const [spaces, setSpaces] = useState([]);
    const [selectedSpace, setSelectedSpace] = useState({
        id: 0,
        name: '',
        description: '',
        image: 'random',
        role: 'reader',
        sets: [],
    });

    const fetchSpaces = (navigateTo, movingTo = 'space', spaceTarget = undefined) => {
        const controlSpinner = !spinnerIsVisible;
        if (controlSpinner) {
            showSpinner('Atualizando seus espaços...');
        }
        SpacesService.list().then(response => {
            const newState = response.data?.spaces ?? [];
            if (newState?.length) {
                newState.forEach(s => {
                    if (!s.sets) {
                        s.sets = [];
                    }
                    s.sets?.forEach(a => {
                        if (!a.habits) {
                            a.habits = [];
                        }
                        a.habits.forEach(h => {
                            if (!h.schedules) {
                                h.schedules = [];
                            }
                            if (!h.goals) {
                                h.goals = [];
                            }
                            if (!h.notes) {
                                h.notes = [];
                            }
                            h.goals.forEach(g => {
                                if (!g.answers) {
                                    g.answers = [];
                                }
                            })
                        })
                    })
                })
            }
            setSpaces(
                newState
            );
            if (newState.length > 0) {
                setCanFetch(false);
            }
            if (controlSpinner || location.pathname !== '/links/import') {
                hideSpinner();
            }
            if (nextUrl) {
                navigate(nextUrl);
                return;
            }
            if (navigateTo) {
                if (movingTo === 'set') {
                    navigate(`/listas/${spaceTarget}/${navigateTo}`);
                } else {
                    navigate(`/meus-espacos/${navigateTo}`);
                }
            } else if (newState.length > 0 && ['/', '/meus-espacos', '/login', '/cadastro'].includes(location.pathname)) {
                navigate(`/meus-espacos/${newState[0].id}`);
            }
        });
    }

    const updateSet = (action, data) => {
        const newSpaces = JSON.parse(JSON.stringify(spaces));
        let _space = newSpaces.find(s => s.id === data.spaceId);

        if (action === 'update') {
            let index = 0;
            for (let set of _space.sets) {
                if (set.id === data.id) {
                    _space.sets[index] = {
                        ..._space.sets[index],
                        ...data
                    };
                    break;
                }
                index += 1;
            }
        } else if (action === 'delete') {
            let index = 0;
            for (let _s of _space.sets) {
                if (_s.id === data.id) {
                    break;
                }
                index += 1;
            }
            _space.sets.splice(index, 1);
        } else {
            if (!_space.sets) {
                _space.sets = [];
            }
            _space.sets.push(data);
        }
        setSpaces(newSpaces);
    }

    const updateHabit = (action, data) => {
        const newSpaces = JSON.parse(JSON.stringify(spaces));
        let spaceIndex = 0;
        for (let _space of newSpaces) {
            if (_space.id === data.spaceId) {
                break;
            }
            spaceIndex += 1;
        }
        let setIndex = 0;
        for (let _set of newSpaces[spaceIndex].sets) {
            if (_set.id === data.setId) {
                break;
            }
            setIndex += 1;
        }

        if (action === 'update') {
            let habitIndex = 0;
            for (let habit of newSpaces[spaceIndex].sets[setIndex].habits) {
                if (habit.id === data.id) {
                    break;
                }
                habitIndex += 1;
            };
            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex] = {
                ...newSpaces[spaceIndex].sets[setIndex].habits[habitIndex],
                ...data,
            };
        } else if (action === 'delete') {
            let habitIndex = 0;
            for (let habit of newSpaces[spaceIndex].sets[setIndex].habits) {
                if (habit.id === data.id) {
                    break;
                }
                habitIndex += 1;
            };
            newSpaces[spaceIndex].sets[setIndex].habits.splice(habitIndex, 1);
        } else {
            if (!newSpaces[spaceIndex].sets[setIndex].habits) {
                newSpaces[spaceIndex].sets[setIndex].habits = [];
            }
            newSpaces[spaceIndex].sets[setIndex].habits.push(data);
        }
        setSpaces(newSpaces);
    }

    const updateSchedule = (action, data) => {
        const newSpaces = JSON.parse(JSON.stringify(spaces));
        let spaceIndex = 0;
        for (let _space of newSpaces) {
            if (_space.id === data.spaceId) {
                break;
            }
            spaceIndex += 1;
        }
        let setIndex = 0;
        for (let _set of newSpaces[spaceIndex].sets) {
            if (_set.id === data.setId) {
                break;
            }
            setIndex += 1;
        }
        let habitIndex = 0;
        for (let habit of newSpaces[spaceIndex].sets[setIndex].habits) {
            if (habit.id === data.habitId) {
                break;
            }
            habitIndex += 1;
        };

        if (action === 'update') {
            let scheduleIndex = 0;
            for (let schedule of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules) {
                if (schedule.id === data.id) {
                    break;
                }
                scheduleIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules[scheduleIndex] = {
                ...newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules[scheduleIndex],
                ...data,
            };
        } else if (action === 'delete') {
            let scheduleIndex = 0;
            for (let schedule of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules) {
                if (schedule.id === data.id) {
                    break;
                }
                scheduleIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules.splice(scheduleIndex, 1);
        } else {
            if (!newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules) {
                newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules = [];
            }
            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].schedules.push(data);
        }
        setSpaces(newSpaces);
    }

    const updateNote = (action, data) => {
        const newSpaces = JSON.parse(JSON.stringify(spaces));
        let spaceIndex = 0;
        for (let _space of newSpaces) {
            if (_space.id === data.spaceId) {
                break;
            }
            spaceIndex += 1;
        }
        let setIndex = 0;
        for (let _set of newSpaces[spaceIndex].sets) {
            if (_set.id === data.setId) {
                break;
            }
            setIndex += 1;
        }
        let habitIndex = 0;
        for (let habit of newSpaces[spaceIndex].sets[setIndex].habits) {
            if (habit.id === data.habitId) {
                break;
            }
            habitIndex += 1;
        };

        if (action === 'update') {
            let noteIndex = 0;
            for (let note of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes) {
                if (note.id === data.id) {
                    break;
                }
                noteIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes[noteIndex] = {
                ...newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes[noteIndex],
                ...data,
            };
        } else if (action === 'delete') {
            let noteIndex = 0;
            for (let note of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes) {
                if (note.id === data.id) {
                    break;
                }
                noteIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes.splice(noteIndex, 1);
        } else {
            if (!newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes) {
                newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes = [];
            }
            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].notes.push(data);
        }
        setSpaces(newSpaces);
    }

    const updateGoal = (action, data) => {
        const newSpaces = JSON.parse(JSON.stringify(spaces));
        let spaceIndex = 0;
        for (let _space of newSpaces) {
            if (_space.id === data.spaceId) {
                break;
            }
            spaceIndex += 1;
        }
        let setIndex = 0;
        for (let _set of newSpaces[spaceIndex].sets) {
            if (_set.id === data.setId) {
                break;
            }
            setIndex += 1;
        }
        let habitIndex = 0;
        for (let habit of newSpaces[spaceIndex].sets[setIndex].habits) {
            if (habit.id === data.habitId) {
                break;
            }
            habitIndex += 1;
        };

        if (action === 'update') {
            let goalIndex = 0;
            for (let goal of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals) {
                if (goal.id === data.id) {
                    break;
                }
                goalIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals[goalIndex] = {
                ...newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals[goalIndex],
                ...data,
            };
        } else if (action === 'delete') {
            let goalIndex = 0;
            for (let goal of newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals) {
                if (goal.id === data.id) {
                    break;
                }
                goalIndex += 1;
            };

            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals.splice(goalIndex, 1);
        } else {
            if (!newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals) {
                newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals = [];
            }
            newSpaces[spaceIndex].sets[setIndex].habits[habitIndex].goals.push(data);
        }
        setSpaces(newSpaces);
    }

    useEffect(() => {
        if (isAuthenticated && canFetch) {
            fetchSpaces();
        }
    }, [isAuthenticated, location.pathname, navigate, canFetch]);

    useEffect(() => {
        const splitedPath = location.pathname.slice(1).split('/');
        if (splitedPath[0] !== '') {
            const asNumber = parseInt(splitedPath[1]);
            if (!isNaN(asNumber) && spaces.map(i => i.id).includes(asNumber)) {
                setSpaceId(asNumber);
            } else {
                if (splitedPath[0] === 'meus-espacos' && spaces.length > 0) {
                    setSpaceId(spaces[0].id);
                    navigate(`/meus-espacos/${spaces[0].id}`);
                }
            }
        }
    }, [location.pathname, spaces, navigate]);

    useEffect(() => {
        const _ = spaces.find(a => a.id === spaceId);
        if (_) {
            setSelectedSpace({..._});
        }
    }, [spaces, spaceId]);

    return (
        <SpacesManager.Provider value={{
            spaces,
            selectedSpace,
            updateSpaces: fetchSpaces,
            updateSet,
            updateHabit,
            updateSchedule,
            updateNote,
            updateGoal,
        }}>
            {children}
        </SpacesManager.Provider>
    )
}