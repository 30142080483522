import axios from "axios";
import api from "./api";

export class UserService {
    static async getLink(data) {
        return api.post('/links/upload', data)
    }

    static async uploadImage(url, data, extension) {
        return axios.put(url, data, {
            headers: {
                'Content-Type': `image/${extension}`
            }
        })
    }

    static async updateUser(data) {
        return api.put('/auth/user', data)
    }
}