import api from "./api";

export const TOKEN_KEY = "@habits-token";
export const USER_KEY = "@habits-token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = user => {
    const token = localStorage.getItem(TOKEN_KEY);
    return token;
};

export const unregisterNotification = async () => {
    const deviceId = localStorage.getItem('deviceId');
    if (deviceId) {
        try {
            await api.delete('/schedules/registry-notification/' + deviceId);
        } catch {};
    }
}

export const logout = async () => {
    await unregisterNotification();
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload();
};

export class AuthService {
    static async identity() {
        return api.get('/auth/me');
    }

    static async login(data) {
        return api.post('/auth/login', data);
    }

    static async register(data) {
        return api.post('/auth', data);
    }

    static async deleteDevice(deviceId) {
        return api.delete('/schedules/registry-notification/' + deviceId);
    }
}