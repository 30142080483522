import api from "./api";

export class SetsService {
    static async updateOrCreate(space) {
        if (space.id) {
            return api.put(`/sets`, space);
        }
        return api.post(`/sets`, space);
    }

    static async deleteSpace(spaceId) {
        return api.delete(`/sets/${spaceId}`);
    }
}