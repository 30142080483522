import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Divider, FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { useNotifications } from '../../../contexts/notification';
import { useSpaces } from '../../../contexts/spaces';
import { SchedulesService } from '../../../services/schedules';
import { isMobile } from '../../../constants';


export default function HandleSchedules(props) {

    const { showSnack } = useNotifications();
    const { updateSchedule } = useSpaces();
    const schedule = useMemo(() => props.schedule);
    const [selectedWeekdays, setSelectedWeekdays] = useState(weekdays);
    const [selectedWeekdayPreset, setSelectedWeekdayPreset] = useState(weekDaysPresets[0]);
    const [hoursInterval, setHoursInterval] = useState([]);
    const [minutesIntervalPreset, setMinutesIntervalPreset] = useState(minutesPresets[0]);
    const [minutesInterval, setMinutesInterval] = useState([minutes[0]]);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        const data = new FormData(e.currentTarget);
        try {
            const [
                pushNotification,
                email,
                enabled,
                recurrent,
                startAt,
                description,
            ] = [
                    Boolean(data.get('push-notification')),
                    Boolean(data.get('email')),
                    Boolean(data.get('enabled')),
                    true,
                    data.get('start-date').split('/').reverse().join('-'),
                    data.get('description').trim(),
                ];
            const _weekdays = {};
            selectedWeekdays.forEach(a => {
                _weekdays[a.value] = true;
            })
            const _hours = {};
            hoursInterval.forEach(a => {
                _hours[a] = true;
            })
            const _minutes = {};
            minutesInterval.forEach(a => {
                _minutes[a] = true;
            })
            const dataToSend = {
                id: schedule.id,
                description: description ?? undefined,
                pushNotification,
                email,
                enabled,
                recurrent,
                startAt,
                weekdays: _weekdays,
                hours: _hours,
                minutes: _minutes,
                habitId: schedule.habitId
            };
            const response = await SchedulesService.updateOrCreate(dataToSend);
            // response.data.id
            updateSchedule(schedule.id ? 'update' : 'create', {
                ...dataToSend,
                setId: schedule.setId,
                habitId: schedule.habitId,
                spaceId: schedule.spaceId,
                id: response.data.id ?? schedule.id,
            });
            props.onClose();
        } catch (err) {
            showSnack(err.message, 'error');
        } finally {
            setDisabled(false);
        }
    }

    useEffect(() => {
        const asMap = selectedWeekdays.map(v => parseInt(v.value));
        const compare = [
            [0, 1, 2, 3, 4, 5, 6],
            [1, 2, 3, 4, 5],
            [0, 6]
        ]
        let index = 0;
        let matched = false;
        for (let value of compare) {
            if (value.length === asMap.length && value.every(_v => asMap.includes(_v))) {
                setSelectedWeekdayPreset(
                    weekDaysPresets[index]
                );
                matched = true;
                break;
            }
            index += 1;
        }
        if (!matched) {
            setSelectedWeekdayPreset(
                weekDaysPresets[3]
            );
        }
    }, [selectedWeekdays]);

    useEffect(() => {
        for (let value of minutesPresets) {
            if (value.value?.every) {
                if (
                    value.value.length === minutesInterval.length &&
                    value.value.every(v => minutesInterval.includes(v))
                ) {
                    setMinutesIntervalPreset(value);
                    break;
                }
            } else {
                setMinutesIntervalPreset(value);
            }
        }
    }, [minutesInterval]);

    useEffect(() => {
        console.log(schedule);

        let _weekdays = schedule.weekdays ? Object.keys(schedule.weekdays) : [
            '1', '2', '3', '4', '5'
        ];

        setSelectedWeekdays(
            _weekdays?.map(w => weekdays[parseInt(w)])
        );

        let _hours = schedule.hours ? Object.keys(schedule.hours) : [];
        setHoursInterval(
            _hours?.map(h => hours[parseInt(h)])
        );

        let _minutes = schedule.minutes ? Object.keys(schedule.minutes) : ['0'];
        setMinutesInterval(
            _minutes?.map(m => minutes[parseInt(m)])
        );
    }, [schedule]);

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'md'}
            fullScreen={isMobile}
            onClose={props.onClose}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>{!schedule?.id ? 'Criar' : 'Modificar'} alarme</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Descrição do alarme"
                            name="description"
                            defaultValue={schedule?.description ?? ''}
                        />
                        <Divider
                            sx={{
                                my: 1,
                            }}
                            variant="middle"
                        />
                        <FormControlLabel control={
                            <Switch
                                name={'push-notification'}
                                defaultChecked={schedule.pushNotification}
                            />
                        } label={'Receber notificação'}
                        />
                        <FormControlLabel control={
                            <Switch
                                name={'email'}
                                defaultChecked={schedule.email}
                            />
                        } label={'Receber e-mail'}
                        />
                        <Divider variant="middle" />
                        <Box width={'100%'} sx={{ mt: 3 }}>
                            <Autocomplete
                                options={weekDaysPresets}
                                getOptionLabel={(option) => option.label}
                                disableClearable
                                value={selectedWeekdayPreset}
                                fullWidth
                                onChange={(_, v) => {
                                    if (v.value === weekDaysPresets[0].value) {
                                        setSelectedWeekdays(weekdays);
                                    } else if (v.value === weekDaysPresets[1].value) {
                                        setSelectedWeekdays(weekdays.slice(1, 6));
                                    } else if (v.value === weekDaysPresets[2].value) {
                                        setSelectedWeekdays([
                                            weekdays[0],
                                            weekdays[6]
                                        ]);
                                    }
                                    setSelectedWeekdayPreset(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="standard"
                                        label="Dias da semana"
                                    />
                                )}
                            />
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                fullWidth
                                options={weekdays}
                                getOptionLabel={(option) => option.label}
                                value={selectedWeekdays}
                                onChange={(_, newValue) => setSelectedWeekdays(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="standard"
                                        label="Dias da semana"
                                    />
                                )}
                            />
                        </Box>

                        <Box width={'100%'} sx={{ mt: 2 }}>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                options={hours}
                                value={hoursInterval}
                                fullWidth
                                onChange={(_, v) => {
                                    setHoursInterval(v.sort((a, b) => a - b));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="standard"
                                        label="Horas"
                                        helperText={'Em quais horas do dia ou noite receber o alarme'}
                                    />
                                )}
                            />
                        </Box>

                        <Box width={'100%'} sx={{ mt: 3 }}>
                            {/* <Autocomplete
                                options={minutesPresets}
                                getOptionLabel={(option) => option.label}
                                disableClearable
                                value={minutesIntervalPreset}
                                fullWidth
                                onChange={(_, v) => {
                                    if (v.value !== minutesPresets[4].value) {
                                        console.log(v.value);
                                        setMinutesInterval(v.value);
                                    }
                                    setMinutesIntervalPreset(v);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="standard"
                                        label="Frequência"
                                    />
                                )}
                            /> */}
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                fullWidth
                                options={minutes}
                                value={minutesInterval}
                                onChange={(_, newValue) => setMinutesInterval(newValue.sort((a, b) => a - b))}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant="standard"
                                        label="Minutos"
                                    />
                                )}
                            />
                        </Box>
                        <Typography sx={{
                            mt: 2,
                            textOverflow: 'ellipsis',
                            maxWidth: '80%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                        }}>
                            {(hoursInterval.length > 0 && minutesInterval.length > 0) && (
                                `Este alarme tocará às ${generateTimes(hoursInterval, minutesInterval)}`
                            )}
                        </Typography>
                        <Accordion
                            sx={{
                                mt: 4,
                                mb: 2
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`handle-schedules-more-config`}
                            >
                                <Typography variant={'caption'}>
                                    MAIS CONFIGURAÇÕES...
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name={'enabled'}
                                                defaultChecked={typeof schedule.enabled === 'boolean' ? schedule.enabled : true}
                                            />
                                        } label={'Ativar alarme'}
                                    />
                                    <DatePicker
                                        sx={{ mt: 2 }}
                                        label={'Data para começar'}
                                        minDate={dayjs(new Date(schedule.startAt ?? new Date()))}
                                        defaultValue={dayjs(new Date())}
                                        slotProps={{
                                            textField: {
                                                name: 'start-date',
                                                helperText: 'Data em que vamos iniciar as notificações',
                                            },
                                        }}
                                    />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </FormGroup>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Button
                        onClick={async () => {
                            SchedulesService.delete(schedule.id);
                            updateSchedule('delete', {
                                setId: schedule.setId,
                                habitId: schedule.habitId,
                                spaceId: schedule.spaceId,
                                id: schedule.id
                            });
                            props.onClose();
                        }}
                        color={'error'}
                        variant='contained'
                        startIcon={<DeleteIcon />}
                    >Deletar alarme</Button>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}>
                        <Button type='submit' disabled={disabled} variant={'contained'}>Confirmar</Button>
                        <Button sx={{ mt: 2 }} onClick={props.onClose}>Cancelar</Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog >
    );
}

const arrayRange = (max, step = 1) =>
    Array.from(
        { length: Math.floor(max / step) },
        (_, index) => (String(index * step))
    );

const hours = arrayRange(24);
const minutes = arrayRange(60);
const minutesPresets = [
    { label: 'Uma vez a cada hora selecionada', value: [minutes[0]] },
    { label: 'A cada dez minutos', value: arrayRange(60, 10) },
    { label: 'A cada vinte minutos', value: arrayRange(60, 20) },
    { label: 'A cada meia-hora', value: arrayRange(60, 30) },
    { label: 'Personalizado...', value: '0' }
];
console.log(minutesPresets)
const weekDaysPresets = [
    { label: 'Todos os dias', value: '0' },
    { label: 'Dias úteis', value: '1' },
    { label: 'Fins de semana', value: '2' },
    { label: 'Personalizado...', value: '3' },
];
const weekdays = [
    { label: 'Domingo', value: '0' },
    { label: 'Segunda', value: '1' },
    { label: 'Terça', value: '2' },
    { label: 'Quarta', value: '3' },
    { label: 'Quinta', value: '4' },
    { label: 'Sexta', value: '5' },
    { label: 'Sábado', value: '6' },
];

const generateTimes = (hours, minutes) => {
    const times = [];
    const normalize = (value) => {
        const newValue = String(value);
        return newValue.length > 1 ? newValue : '0' + newValue;
    }

    for (let hour of hours) {
        for (let minute of minutes) {
            times.push(`${normalize(hour)}:${normalize(minute)}`);
        }
    }
    return times.join(', ');
}