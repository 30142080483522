import { Box, Typography } from "@mui/material"

export const MyFooter = () => {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
            {/* <Typography variant="h6" align="center" gutterBottom>
                Meus Hábitos
            </Typography> */}
            <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
            >
                Powered by <b>One CI Technology</b>
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
                {`Meus Hábitos © ${new Date().getFullYear()}`}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
                {`Todos os direitos reservados`}
            </Typography>
        </Box>
    );
}