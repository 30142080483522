import { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CheckIcon from '@mui/icons-material/Check';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useNotifications } from '../../../contexts/notification';
import { isMobile } from '../../../constants';
import { Autocomplete, Box, Button, DialogActions, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { GoalsService } from '../../../services/goals';


export const GoalAdvance = (props) => {
    const { showSnack } = useNotifications();
    const [references, setReferences] = useState([]);
    const [referenceValue, setReferenceValue] = useState({ text: '', date: new Date() });
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [canSubmit, setCansubmit] = useState(false);
    const [ canFetch, setCanFetch ] = useState(true);
    const [whatHappened, setWhatHappened] = useState('');
    const [ whats, setWhats ] = useState({
        help: [],
        hard: [],
    });

    const {goal} = useMemo(() => props.data ?? {goal: null}, [props.data]);
    const [ answers, setAnswers ] = useState([]);
    const [kind, setKind] = useState(Kinds.myAdvance);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => {
        return (_, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };
    };

    const submitAnwser = async () => {
        if (!Boolean(goal) || !canSubmit || (
            typeof selectedAnswer !== 'boolean' || !referenceValue?.text
        )) {
            return;
        }

        try {
            const dataToSend = {
                reference: referenceValue.text,
                orderDate: referenceValue.date.toISOString(),
                answer: selectedAnswer,
                whatHappened: (whatHappened ?? '').length > 0 ? whatHappened : undefined,
                goalId: goal?.id,
            };
            await GoalsService.putAnswer(dataToSend);
            setCanFetch(true);
        } catch (err) {
            showSnack(err.message, 'error');
        } finally {
            setCansubmit(false);
        }
    }

    useEffect(() => {
        if (goal) {
            setSelectedAnswer(null);
            setWhatHappened('');
            if (goal.regularity) {
                setReferences(buildReference[goal.regularity](new Date()) ?? []);
            }
        }
    }, [goal]);

    const fetchAnswers = useMemo(() =>
        async () => {
            if (!canFetch && !Boolean(goal)) {
                return;
            }
            setCanFetch(false);

            try {
                const response = await GoalsService.listAnswers(goal.id);
                setAnswers(response.data.answers);
            } catch {}
        }
    );

    useEffect(() => {
        if (goal && canFetch) {
            fetchAnswers();
        } else if (!Boolean(goal)) {
            setCanFetch(true);
            setAnswers([]);
            setWhats({help: [], hard: []});
        }
    }, [ goal, canFetch ]);

    useEffect(() => {
        if (references && references.length > 0) {
            setReferenceValue(references[
                references.length - 1
            ]);
        };
    }, [references]);

    useEffect(() => {
        if (referenceValue?.text && answers) {
            const answer = answers.find(a => {
                return a.reference === referenceValue.text
            });
            if (answer) {
                setSelectedAnswer(answer.answer);
                setWhatHappened(answer.whatHappened);
            } else {
                setSelectedAnswer(null);
                setWhatHappened('');
            }
        }
    }, [referenceValue, answers, kind]);

    useEffect(() => {
        if (typeof selectedAnswer === 'boolean' && canSubmit) {
            submitAnwser();
        }
    }, [selectedAnswer, canSubmit])

    useEffect(() => {
        if (answers && answers.length > 0) {
            const newData = {help: [], hard: []};
            for (let answer of answers) {
                if (!answer.whatHappened || !answer.whatHappened?.length) {
                    continue;
                };
                newData[answer.answer ? 'help' : 'hard'].push(answer.whatHappened);
            }
            setWhats(newData);
        }
    }, [ answers ]);

    const content = useMemo(() => {
        return {
            myAdvance: (
                <>
                    <Box sx={{
                        maxHeight: '35vh',
                        overflowY: 'auto',
                        mt: 1
                    }}>
                        <Stack>
                            {answers?.map((answer, index) => {
                                return (
                                    <Button
                                        key={`${goal?.id}-${answer.reference}-${index}`}
                                        fullWidth
                                        onClick={() => {
                                            setKind(Kinds.putAnswer);
                                            setReferenceValue(references.find(a => a.text === answer.reference));
                                        }}
                                        color={answer.answer ? 'success' : 'info'}
                                        variant='contained'
                                        sx={{
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Typography>{answer.reference}</Typography>
                                        {answer.answer ?
                                            <SentimentVerySatisfiedIcon /> :
                                            <SentimentDissatisfiedIcon />
                                        }
                                    </Button>
                                )
                            })}
                        </Stack>
                    </Box>
                    <Box>
                        {whats.help.length > 0 && (
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>
                                    O que tem me ajudado
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {whats.help.map(w => (
                                    <Typography>{w}</Typography>
                                ))}
                            </AccordionDetails>
                            </Accordion>
                        )}
                        {whats.hard.length > 0 && (
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>
                                    Minhas dificuldades
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {whats.hard.map(w => (
                                    <Typography>{w}</Typography>
                                ))}
                            </AccordionDetails>
                            </Accordion>
                        )}
                    </Box>
                </>
            ),
            putAnswer: (
                <>
                    <Autocomplete
                        options={references ?? []}
                        value={referenceValue}
                        getOptionLabel={a => a?.text}
                        onChange={(_, value) => {
                            setReferenceValue(value)
                        }}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant="standard"
                                label="Referência"
                            />
                        )}
                    />
                    <Stack
                        direction={'row'}
                        spacing={1}
                        sx={{
                            justifyContent: 'center',
                        }}
                        my={3}
                    >
                        <Button
                            variant={'contained'}
                            color={selectedAnswer === false ? 'primary' : 'secondary'}
                            sx={{
                                width: '10rem'
                            }}
                            onClick={() => {
                                if (selectedAnswer !== false) {
                                    setCansubmit(true);
                                }
                                setSelectedAnswer(false);
                            }}
                        >
                            <Box>
                                <SentimentDissatisfiedIcon />
                                <Typography>
                                    Não deu certo dessa vez
                                </Typography>
                            </Box>
                        </Button>
                        <Button
                            variant={'contained'}
                            color={selectedAnswer === true ? 'primary' : 'secondary'}
                            sx={{
                                width: '10rem'
                            }}
                            onClick={() => {
                                if (selectedAnswer !== true) {
                                    setCansubmit(true);
                                }
                                setSelectedAnswer(true);
                            }}
                        >
                            <Box>
                                <SentimentVerySatisfiedIcon />
                                <Typography>
                                    Eu consegui!
                                </Typography>
                            </Box>
                        </Button>
                    </Stack>
                    {typeof selectedAnswer === 'boolean' && <TextField
                        value={whatHappened}
                        onChange={(e) => setWhatHappened(e.target.value)}
                        onBlur={() => {
                            setCansubmit(true)
                        }}
                        label={selectedAnswer ? 'O que te ajudou?' : 'O que dificultou?'}
                        variant={'outlined'}
                        fullWidth
                    />}

                </>
            )
        }
    });

    useEffect(() => {
        if (props.data?.kind) {
            setKind(props.data.kind);
        }
    }, [props.data]);


    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile}
            open={Boolean(goal)}
            onClose={props.onClose}
        >
            <DialogTitle>{goal?.name}</DialogTitle>
            <DialogContent>
                <Typography sx={{mb: 1}} variant='subtitle1'>{goal?.description}</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        value={kind} onChange={(e, value) => {
                            setKind(value);
                        }}
                    >
                        <Tab value={Kinds.myAdvance} label="Meu Progresso" id={'advance-tab-0'} />
                        <Tab value={Kinds.putAnswer} label="Enviar Resposta" id={'advance-tab-1'} />
                    </Tabs>
                </Box>
                {content[kind]}
            </DialogContent>
            <DialogActions>
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={'flex-end'}
                >
                    <Button
                        variant='contained'
                        startIcon={<CheckIcon />}
                        onClick={async () => {
                            props.onClose();
                        }}
                    >
                        Concluir
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>

    );
}


const buildReference = {
    dayle: () => {
        const references = [];
        for (let i = -15; i <= 0; i++) {
            const now = new Date();
            now.setDate(
                now.getDate() + i
            );
            references.push({
                text: `Dia ${now.toLocaleDateString('pt-BR', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                })
                    }`, date: now
            });
        }
        return references;
    },
    '1-week': () => {
        const references = [];
        for (let i = -8; i <= 0; i++) {
            const now = new Date();
            const delta = now.getDate() + (i * 7);
            const weekday = now.getDay();

            now.setDate(
                delta - weekday
            );
            const endWeek = new Date(now);
            endWeek.setDate(
                endWeek.getDate() + 6
            );
            references.push({
                text: `Semana de ${now.toLocaleDateString('pt-BR', {
                    month: 'short',
                    day: '2-digit',
                })
                    } até ${endWeek.toLocaleDateString('pt-BR', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric'
                    })
                    }`, date: endWeek
            });
        }
        return references;
    },
    '2-week': () => {
        const references = [];
        for (let i = -8; i <= 8; i++) {
            const now = new Date();
            const delta = now.getDate() + (i * 14);
            const weekday = now.getDay();

            now.setDate(
                delta - weekday
            );
            const endWeek = new Date(now);
            endWeek.setDate(
                endWeek.getDate() + 13
            );
            references.push({
                text: `Semanas de ${now.toLocaleDateString('pt-BR', {
                    month: 'short',
                    day: '2-digit',
                })
                    } até ${endWeek.toLocaleDateString('pt-BR', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric'
                    })
                    }`, date: endWeek
            });
        }
        return references;
    },
    montly: () => {
        const references = [];
        for (let i = -10; i <= 0; i++) {
            const now = new Date();
            now.setMonth(
                now.getMonth() + i
            );
            references.push({
                text: `${now.toLocaleDateString('pt-BR', {
                    month: 'long',
                    year: 'numeric'
                })
                    }`, date: now
            });
        }
        return references;
    },
};

const Kinds = {
    myAdvance: 'myAdvance',
    putAnswer: 'putAnswer',

}
const orderedKinds = [Kinds.myAdvance, Kinds.putAnswer];
