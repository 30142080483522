import { useLocation, useNavigate } from 'react-router';

import { Button, Divider, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { HandleNotes } from './handle';
import { useEffect, useMemo, useState } from 'react';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import './styles.css';
import { hashPath } from '../../../constants';


export const NotesComponent = ({ notes, ids }) => {
	const location = useLocation();
	const navigate = useNavigate();
    const [hash, hashTargetId] = useMemo(() => {
        const splited = location.hash?.split('?');
        return  [splited[0] ?? '', splited[1] ?? null];
    });

    const [ handledNote, setHandledNote ] = useState(null);

    const closeHandledNote = () => {
        if (Object.values(hashPath.notes).includes(hash)) {
            navigate(location.pathname);
        } else {
            setHandledNote(null);
        }
    }

    useEffect(() => {
        if (hash === hashPath.notes.update) {
            const note = notes?.find(n => n.id.toString() === hashTargetId);
            if (note) {
                setHandledNote(note);
            }
        } else if (hash === hashPath.notes.create) {
            if (hashTargetId === ids.habitId.toString()) {
                setHandledNote({
                    ...ids
                });
            }
        } else {
            closeHandledNote();
        }
    }, [hash, hashTargetId, location]);

    useEffect(() => {
        console.log(notes);
    }, [ notes ]);

    return (
        <Box>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="div">
                        Anotações
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1, width: '40%', mr: 3 }} />
                    <Grid container spacing={5}>
                        {notes?.map((note, index) => {
                            return (
                                <Grid item key={`${note.id}-${index}`}  xs={12} sm={6} md={4}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            navigate(`${hashPath.notes.update}?${note.id}`);
                                        }}
                                    >
                                        <CardContent>
                                            <div dangerouslySetInnerHTML={{
                                                __html: `<div class="preview-note">${note.content}</div>`
                                            }} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
                <CardActions sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                }}>
                    <Button
                        startIcon={<NoteAddIcon />}
                        size="small"
                        variant={'contained'}
                        onClick={() => {
                            navigate(`${hashPath.notes.create}?${ids.habitId}`);
                        }}
                    >Nova anotação</Button>
                </CardActions>
            </Card>
            <HandleNotes note={handledNote} onClose={closeHandledNote} />
        </Box>
    );
}
