import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useMemo, useState } from 'react';
import { useSpaces } from '../../contexts/spaces';
import { useNotifications } from '../../contexts/notification';
import { SetsService } from '../../services/sets';
import { UploadImageFile } from '../../components/UploadImageFile';
import { UserService } from '../../services/user';
import { Buffer } from "buffer"
import { useNavigate, useLocation } from 'react-router';


export default function HandleSets(props) {
    const { showSnack } = useNotifications();
    const { updateSet } = useSpaces();
    const [disabled, setDisabled] = useState(false);
    const set = useMemo(() => props.set);
    const [hero, setHero] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        const data = new FormData(e.currentTarget);
        try {
            const [name, description] = [
                data.get('name'),
                data.get('description'),
            ];
            let body = {
                name, description, id: set.id > 0 ? set.id : undefined,
                spaceId: set.spaceId,
                image: 'random'
            }

            if (hero) {
                let extension = hero.type.split('/')[1]
                let arrayBuffer = await hero.arrayBuffer()
                let buffer = Buffer.from(arrayBuffer)
                let data = {
                    case: "hero",
                    extension
                }

                const links = await UserService.getLink(data)
                await UserService.uploadImage(links.data.uploadUrl, buffer, extension)
                body.image = links.data.publicUrl
            }

            const response = await SetsService.updateOrCreate(body);
            updateSet(set.id > 0 ? 'update' : 'create', {
                ...set,
                habits: set.habits ?? [],
                name, description,
                id: response.data.id ?? set.id,
                createdAt: new Date(),
                image: body.image
            });
            // await updateSpaces(response.data.id, 'set', set.spaceId);
            props.onClose();
        } catch (err) {
            showSnack(err.message, 'error');
        } finally {
            setDisabled(false);
        }
    }
    useEffect(() => {
        if (set.image !== "random") {
            setHero(set.image)
        }
    }, [set])

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={"md"}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{!set?.id ? 'Criar' : 'Modificar'} lista</DialogTitle>
                <DialogContent>
                    <UploadImageFile hero={hero} setHero={setHero} />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nome"
                        fullWidth
                        name={'name'}
                        defaultValue={set?.name ?? ''}
                        required
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Descrição"
                        fullWidth
                        name={'description'}
                        defaultValue={set?.description ?? ''}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={props.onClose}>Cancelar</Button>
                    <Button type='submit' disabled={disabled} variant={'contained'}>Confirmar</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}