import { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';

import { useSpaces } from '../../../contexts/spaces';
import { useNotifications } from '../../../contexts/notification';
import { isMobile, weekdays } from '../../../constants';
import { Autocomplete, Box, Button, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { GoalsService } from '../../../services/goals';


export const HandleGoal = (props) => {
    const { updateGoal } = useSpaces();
    const { showSnack } = useNotifications();

    const goal = useMemo(() => props.goal);
    const [hideRegularity, setHideRegularity] = useState(goal?.kind !== 'reach');
    const [regularity, setRegularity] = useState(goal?.regularity);
    const [sendNotification, setSendNotification] = useState(typeof goal?.ask === 'boolean' ? goal.ask : true);
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        const data = new FormData(e.currentTarget);
        try {
            const [
                askIn,
                askInMonthDay,
                _askInWeekday,
                ask,
                regularity,
                kind,
                description,
                name
            ] = [
                    data.get('askIn'),
                    data.get('askInMonthDay'),
                    data.get('askInWeekday'),
                    Boolean(data.get('ask')),
                    data.get('regularity'),
                    data.get('kind'),
                    data.get('description'),
                    data.get('name'),
                ];
            const dataToSend = {
                id: goal.id,
                habitId: goal.habitId,
                kind,
                description,
                name,
            };
            if (dataToSend.kind !== 'reach') {
                dataToSend.regularity = regularity;
                dataToSend.ask = Boolean(ask);
                dataToSend.askIn = askIn;

                if (dataToSend.ask && dataToSend.askIn.length < 5) {
                    throw new Error('Horário inválido para notificação');
                } else if (!dataToSend.ask) {
                    dataToSend.askIn = undefined;
                }

                if (['1-week', '2-week'].includes(regularity)) {
                    const askInWeekday = weekdays.find(a => a.label === _askInWeekday);
                    dataToSend.askInWeekday = parseInt(askInWeekday.value);
                } else if (regularity === 'montly') {
                    dataToSend.askInMonthDay = parseInt(askInMonthDay);
                }
            }

            const response = await GoalsService.updateOrCreate(dataToSend);
            updateGoal(goal.id ? 'update' : 'create', {
                ...dataToSend,
                setId: goal.setId,
                habitId: goal.habitId,
                spaceId: goal.spaceId,
                id: response.data.id ?? goal.id,
            });
            props.onClose();
        } catch (err) {
            showSnack(err.message ?? err, 'error');
        } finally {
            setDisabled(false);
        }
    }

    useEffect(() => {
        if (goal) {
            setHideRegularity(!goal.kind || goal.kind === 'reach');
            setRegularity(goal.regularity);
            setSendNotification(typeof goal?.ask === 'boolean' ? goal.ask : true);
            setDisabled(false);
        }
    }, [goal]);

    return (
        <Dialog
            fullWidth={true}
            fullScreen={isMobile}
            open={Boolean(goal)}
            onClose={props.onClose}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle>{goal?.id ? 'Modificar' : 'Nova'} Meta</DialogTitle>
                <DialogContent>
                    <TextField
                        name={'name'}
                        label={'Nome da meta'}
                        defaultValue={goal?.name}
                        variant='outlined'
                        fullWidth
                        required
                    />
                    <TextField
                        name={'description'}
                        label={'Descrição da meta'}
                        defaultValue={goal?.description}
                        variant='outlined'
                        fullWidth
                        multiline
                        helperText={'Tente detalhar bem a meta que você vai alcançar'}
                    />
                    <Divider sx={{ width: '30%', my: 1 }} />
                    <FormControl fullWidth>
                        <FormLabel>Tipo de meta</FormLabel>
                        <RadioGroup
                            defaultValue={goal?.kind ?? 'start'}
                            name="kind"
                            onChange={(_, value) => {
                                setHideRegularity(value === 'reach');
                            }}
                        >
                            <FormControlLabel value="start" control={<Radio />} label="Quero começar a fazer" />
                            <FormControlLabel value="dev" control={<Radio />} label="Até já faço, mas preciso melhorar" />
                            <FormControlLabel value="reach" control={<Radio />} label="É algo a ser atingido uma vez" />
                            <FormControlLabel value="stop" control={<Radio />} label="Minha meta é parar de fazer alguma coisa" />
                        </RadioGroup>
                    </FormControl>
                    {!hideRegularity && (
                        <>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <FormLabel>Regularidade</FormLabel>
                                <RadioGroup
                                    defaultValue={goal?.regularity ?? 'dayle'}
                                    name="regularity"
                                    onChange={(_, value) => {
                                        setRegularity(value);
                                    }}
                                >
                                    <FormControlLabel value="dayle" control={<Radio />} label="Diária" />
                                    <FormControlLabel value="1-week" control={<Radio />} label="Semanal" />
                                    <FormControlLabel value="montly" control={<Radio />} label="Mensal" />
                                </RadioGroup>
                            </FormControl>
                            <Divider sx={{ width: '30%', my: 1 }} />
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(_, checked) => setSendNotification(checked)}
                                        name={'ask'}
                                        defaultChecked={typeof goal?.ask === 'boolean' ? goal.ask : true}
                                    />
                                } label={'Enviar notificação'}
                            />
                            {
                                sendNotification && (<>
                                    {(['1-week', '2-week'].includes(regularity)) && (
                                        <Autocomplete
                                            fullWidth
                                            options={weekdays}
                                            defaultValue={weekdays.find(a => a.value === goal?.askInWeekday?.toString())}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    name='askInWeekday'
                                                    variant="outlined"
                                                    label="Dia da semana"
                                                />
                                            )}
                                        />
                                    )}
                                    {(regularity === 'montly') && (
                                        <Autocomplete
                                            fullWidth
                                            sx={{ mt: 1 }}
                                            options={monthDays}
                                            defaultValue={goal?.askInMonthDay}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name='askInMonthDay'
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Dia do mês"
                                                />
                                            )}
                                        />
                                    )}
                                    <TimePicker
                                        sx={{ mt: 2 }}
                                        label={'Horário'}
                                        defaultValue={goal?.askIn && dayjs(`2000-01-01T${goal.askIn}`)}
                                        slotProps={{
                                            textField: {
                                                name: 'askIn',
                                                variant: 'outlined',
                                                fullWidth: true
                                            },
                                        }}
                                    />
                                </>)
                            }
                        </>)}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <IconButton
                        onClick={async () => {
                            GoalsService.delete(goal.id);
                            updateGoal('delete', {
                                setId: goal.setId,
                                habitId: goal.habitId,
                                spaceId: goal.spaceId,
                                id: goal.id
                            });
                            props.onClose();
                        }}
                        color={'error'}
                        variant='contained'
                    ><DeleteIcon /></IconButton>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                    }}>
                        <Button type='submit' disabled={disabled} variant={'contained'}>Confirmar</Button>
                        <Button sx={{ mt: 2 }} onClick={props.onClose}>Cancelar</Button>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>

    );
}

const arrayRange = (begin, max, step = 1) =>
    Array.from(
        { length: Math.floor((max - begin) / step) },
        (_, index) => (String(begin + (index * step)))
    );

const monthDays = arrayRange(1, 32);
