import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { createTheme, ThemeProvider as MUIProvider } from '@mui/material/styles';
import { COLORS } from "../constants";


export const ThemeManager = createContext({});
export const useTheme = () => useContext(ThemeManager);


export const ThemeProvider = ({ children }) => {
    const [mode, setMode] = useState(localStorage.getItem('theme') ?? 'light');
    
    useEffect(() => {
        localStorage.setItem('theme', mode);
    }, [ mode ]);
    
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === 'light' ? 'dark' : 'light',
                );
            },
        }),
        [],
    );

    const theme = useMemo(() => createTheme({
        palette: {
            mode,
            primary: {
                main: COLORS.primary,
            }
        }
    }));


    return (
        <MUIProvider theme={theme}>
            <ThemeManager.Provider value={{
                colorMode,
                toggleTheme: colorMode.toggleColorMode,
                theme: mode,
            }}>
                {children}
            </ThemeManager.Provider>
        </MUIProvider>
    )
}