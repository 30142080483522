export const COLORS = {
    primary: '#550be5',
    lessWhite: '#f9f9f9',
    white: '#fff',
};

export const RANDOM_IMAGE_URL = 'https://source.unsplash.com/random?wallpapers';

export const FRONTEND_URL = 'https://meushabitos.com';

export const getUserAgent = () => {
    return window.navigator.userAgent;
}

export const verifyIfIsMobile = () => {
    const agent = getUserAgent();
    return Boolean(
        agent.match(/Android/i)
         || agent.match(/webOS/i)
         || agent.match(/iPhone/i)
         || agent.match(/iPad/i)
         || agent.match(/iPod/i)
         || agent.match(/BlackBerry/i)
         || agent.match(/Windows Phone/i)
    );
}

export const isMobile = verifyIfIsMobile();

export const hashPath = {
    spaces: {
        create: '#criar-espaco',
        update: '#modificar-espaco',
        delete: '#deletar-espaco',
    },
    sets: {
        create: '#criar-lista',
        update: '#modificar-lista',
        delete: '#deletar-lista',
    },
    habits: {
        create: '#criar-habito',
        update: '#modificar-habito',
        delete: '#deletar-habito',
    },
    schedule: {
        create: '#criar-alarme',
        update: '#modificar-alarme',
        delete: '#deletar-alarme',
    },
    notes: {
        update: '#modificar-nota',
        create: '#criar-nota',
    },
    goals: {
        update: '#modificar-meta',
        create: '#criar-meta',
        delete: '#deletar-meta',
        putAnswer: '#enviar-resposta',
        myAdvance: '#meu-avanco'
    }
}

export const weekdays = [
    { label: 'Domingo', value: '0' },
    { label: 'Segunda', value: '1' },
    { label: 'Terça', value: '2' },
    { label: 'Quarta', value: '3' },
    { label: 'Quinta', value: '4' },
    { label: 'Sexta', value: '5' },
    { label: 'Sábado', value: '6' },
];