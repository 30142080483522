import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthService, TOKEN_KEY } from "../services/auth";
import { useNotifications } from "./notification";
import { useSpinner } from "./LoadSpinner";


export const AuthManager = createContext({});
export const useAuth = () => useContext(AuthManager);

export const AuthProvider = ({ children }) => {
    const { showSpinner, spinnerIsVisible } = useSpinner();
    const notification = useNotifications();
    const [ user, setUser ] = useState({});
    const [ isAuthenticated, setIsAuthenticated ] = useState(!!(localStorage.getItem(TOKEN_KEY)));

    const logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        window.location.reload();
    }

    const makeLogin = async (token) => {
        if (token) {
            const controlSpinner = !spinnerIsVisible;
            if (controlSpinner) {
                showSpinner('Obtendo seus espaços...');
            }
            AuthService.identity().then(async ({data}) => {
                setUser(data);
                if (data.habePushActive) {
                    await notification.askUserPermission();
                    await notification.registerPushNotification();
                }
            });
            localStorage.setItem(TOKEN_KEY, token);
            setIsAuthenticated(true);

        }
    }

    useEffect(() => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            makeLogin(token);
        }
    }, []);

    return (
        <AuthManager.Provider value={{
            makeLogin,
            logout,
            user,
            isAuthenticated,
            setUser
        }}>
            {children}
        </AuthManager.Provider>
    )
}

