import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { COLORS, hashPath } from '../../constants';
import { HideOnScroll } from '../../components/HideOnScroll';
import { Avatar, Box, Collapse, Container, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Tooltip } from '@mui/material';
import { useTheme } from '../../contexts/theme';
import { useSpaces } from '../../contexts/spaces';
import HandleSpaces from '../../pages/Space/handleSpace';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { AccountCircle, ExpandLess, ExpandMore } from '@mui/icons-material';
import { AuthManager } from '../../contexts/auth';
import { logout, unregisterNotification } from '../../services/auth';
import { useNotifications } from '../../contexts/notification';
import { SpacesService } from '../../services/spaces';


export const MyHeader = (props) => {
	const location = useLocation();
    const hash = useMemo(() => location.hash ?? '');
    const { showDialog, closeDialog, registerPushNotification } = useNotifications();
    const navigate = useNavigate();
    const { user } = useContext(AuthManager)
    const { spaces, selectedSpace } = useSpaces();
    const [handleSpaces, setHandleSpaces] = useState({
        open: false,
        onClose: () => { },
        space: {},
    });
    const [ sendNotificationsInThisDevice, setSendNotificationsInThisDevice ] = useState(
        !Boolean(localStorage.getItem('dontSendNotificationsInThisDevice'))
    );

    const [ancholEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(ancholEl);
    const [openMenuTheme, setOpenMenuTheme] = useState(false);

    const theme = useTheme();

    const closeHandleSpaces = () => {
        if (Object.values(hashPath.spaces).includes(hash)) {
            navigate(location.pathname);
        } else {
            setHandleSpaces({
                open: false,
                onClose: closeHandleSpaces,
                space: {},
            });
        }
    }

	useEffect(() => {
		if (hash === hashPath.spaces.create) {
            setHandleSpaces({
                open: true,
                onClose: closeHandleSpaces,
                space: {}
            });
        } else if (hash === hashPath.spaces.update) {
            if (selectedSpace?.id) {
                setHandleSpaces({
                    open: true,
                    onClose: closeHandleSpaces,
                    space: selectedSpace
                });
            }
        } else if (hash === hashPath.spaces.delete) {
            if (selectedSpace?.id) {
                showDialog({
                    title: `Deletar permanentemente "${selectedSpace.name}"?`,
                    message: 'Esta ação deletará todas as listas, hábitos e alarmes dentro deste espaço. Esta ação é irreversível. Deseja realmente continuar?',
                    mode: 'confirm',
                    confirmButtonColor: 'error',
                    confirmText: 'Sim, quero deletar',
                    sendAction: async (r) => {
                        if (r === 'confirm') {
                            await SpacesService.deleteSpace(selectedSpace.id);
                            window.location.replace('/meus-espacos');
                        }
                        navigate(location.pathname);
                    }
                });
            }
        }else {
            closeHandleSpaces();
            closeDialog();
        }
	}, [ location, selectedSpace ]);

    useEffect(() => {
        const v = sendNotificationsInThisDevice;
        if (v) {
            localStorage.removeItem('dontSendNotificationsInThisDevice');
            registerPushNotification();
        } else {
            localStorage.setItem('dontSendNotificationsInThisDevice', 'true');
            unregisterNotification();
        }
    }, [ sendNotificationsInThisDevice ]);

    return (<>
        <HideOnScroll {...props}>
            <AppBar color={'transparent'}>
                <Toolbar variant={'dense'} sx={{
                    backgroundColor: COLORS.primary,
                    color: COLORS.white,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {props.backTo ?
                            <IconButton onClick={() => { navigate(props.backTo) }} aria-label="back-to" sx={{ mr: 2 }}>
                                <ArrowBackIcon color={'#fff'} />
                            </IconButton> :
                            <SpaceDashboardIcon sx={{ mr: 2 }} />
                        }
                        <Typography variant="h6" color="inherit" noWrap>
                            {props.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Tooltip title="Ajustes">
                            <IconButton sx={{ ml: 1 }} onClick={(e) => setAnchorEl(e.currentTarget)} color="inherit">
                                {user?.avatar ? <Avatar alt="Image profile" src={user?.avatar} /> : <AccountCircle fontSize='large' />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        id="menu"
                        open={openMenu}
                        anchorEl={ancholEl}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                        <Container maxWidth="sm">
                            <Grid container sx={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', padding: '10px' }}>
                                <Avatar src={user?.avatar} sx={{
                                    width: '50px !important', 
                                    height: '50px !important'
                                }} />
                                <Grid>
                                    <Typography>{user?.firstName} {user.lastName ?? ""}</Typography>
                                    <Typography>{user?.email}</Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        <Divider />
                        <MenuItem onClick={() => {
                            navigate('/minha-conta')
                        }}>Meus dados</MenuItem>
                        <MenuItem onClick={() => setOpenMenuTheme(!openMenuTheme)} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}>
                            <Grid sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px'
                            }}>
                                <Typography>Tema</Typography>
                                {openMenuTheme ? <ExpandMore fontSize={'small'} /> : <ExpandLess fontSize={'small'} />}
                            </Grid>
                            <Collapse in={openMenuTheme} sx={{width: '100%'}}>
                                <List>
                                    <ListItemButton selected={theme.theme === "dark"} onClick={() => { theme.theme !== "dark" && theme.toggleTheme() }}>
                                        <ListItemIcon><Brightness4Icon fontSize={"small"} /></ListItemIcon>
                                        <ListItemText primary="Escuro" />
                                    </ListItemButton>
                                    <ListItemButton selected={theme.theme === "light"} onClick={() => { theme.theme !== "light" && theme.toggleTheme() }}>
                                        <ListItemIcon><Brightness7Icon fontSize={"small"} /></ListItemIcon>
                                        <ListItemText primary="Claro" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </MenuItem>
                        <MenuItem onClick={() => setSendNotificationsInThisDevice(!sendNotificationsInThisDevice)}>
                            <Typography>Notificações neste dispositivo</Typography>
                            <Switch
                                checked={sendNotificationsInThisDevice}
                                onChange={(e, v) => {
                                    setSendNotificationsInThisDevice(v);
                                }}
                            />
                        </MenuItem>
                        <MenuItem onClick={logout}>Sair</MenuItem>
                    </Menu>
                </Toolbar>
                <Toolbar
                    sx={{
                        bgcolor: 'background.paper',
                        borderBottom: 1,
                        display: 'flex',
                        alignItems: 'center',
                        ustifyContent: 'space-between',
                        overflowX: 'auto'
                    }}
                    component="nav"
                    variant="dense"
                >
                    {spaces.map((a, i) => {
                        return (
                            <Button
                                variant={a.id === selectedSpace.id ? 'contained' : 'outlined'} key={`${i}-${a.id}`}
                                sx={{
                                    mr: 2,
                                    flexShrink: 0
                                }}
                                onClick={() => navigate(`/meus-espacos/${a.id}`)}
                                disabled={a.id === selectedSpace.id}
                                color={a.id === selectedSpace.id ? 'primary' : 'info'}
                            >
                                {a.name}
                            </Button>
                        )
                    })}
                    <Button
                        startIcon={<AddIcon />}
                        sx={{
                            mr: 2,
                            flexShrink: 0
                        }}
                        onClick={() => {
                            navigate(hashPath.spaces.create);
                        }}
                        variant={'contained'}>Novo espaço</Button>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
        <HandleSpaces {...handleSpaces} />
    </>)
}