import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import LoggedOut from "../../Layouts/LoggedOut";
import { GoogleLogin } from 'react-google-login';
import { AuthService } from '../../services/auth';
import { useAuth } from '../../contexts/auth';
import { useNavigate, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';


export default function SignInSide() {
	const auth = useAuth();
	const location = useLocation();
	const  [nextUrl, nextUrlEncoded] = useMemo(() => {
		const urlParams = new URLSearchParams(location.search);
		const next = urlParams.get('next');
		if (next) {
			return [atob(next), next];
		}
		return [next, next];
	});

	useEffect(() => {
		console.log(nextUrl)
	}, [ nextUrl ])

	const [ error, setError ] = useState('\t');

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		try {
			const response = await AuthService.login({
				username: data.get('email'),
				password: data.get('password'),
			});
			auth.makeLogin(response.data.access);
		} catch(err) {
			setError(err.response?.data?.message);
		};
	};

	const loginSocial = async (mechanism, token, avatar) => {
		try {
			const { data, status } = await AuthService.login({
				socialLoginToken: token,
				socialLogin: mechanism,
				username: 'social',
				password: 'social',
				avatar,
				
			});
			auth.makeLogin(data.access);
		} catch(err) {
			console.log(err.response.data);
		}
	}

	return (
		<LoggedOut
			title="Login"
			handleSubmit={handleSubmit}
		>
			<Grid container>
				<Grid item sx={{
					width: '100%',
					margin: '15px 0 20px',
					display: 'flex',
					justifyContent: 'center'
				}}>
					<GoogleLogin
						clientId="991878789804-fv60l410um4qlctpmdnqjsjf0oh6s1rq.apps.googleusercontent.com"
						buttonText="Continuar com o Google"
						onSuccess={(r) => {
							loginSocial('google', r.tokenId, r.profileObj?.imageUrl);
						}}
						onFailure={(r) => {console.log(r)}}
					/>
				</Grid>
			</Grid>
			<Typography variant='body1' align='center'>
				OU
			</Typography>
			<TextField
				margin="normal"
				required
				fullWidth
				id="email"
				label="Email Address"
				name="email"
				autoComplete="email"
				autoFocus
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="password"
				label="Password"
				type="password"
				id="password"
				autoComplete="current-password"
			/>
			<Typography variant={'caption'} color={'error'}>
				{error}
			</Typography>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
			>
				Login
			</Button>
			<Grid container>
				<Grid item>
					<Link href={`/cadastro${nextUrl ? '?next=' + nextUrlEncoded : ''}`} variant="body2">
						{"Não tem uma conta? Cadastre-se gratuitamente"}
					</Link>
				</Grid>
			</Grid>
		</LoggedOut>
	)
}