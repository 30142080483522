import React, { createContext, useContext, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import './styles.css';
import { Typography } from "@mui/material";


export const SpinnerManager = createContext({});
export const useSpinner = () => useContext(SpinnerManager);

export const LoadSpinnerProvider = ({ children }) => {
    const [spinnerConfig, setSpinnerConfig] = useState({
        visible: false,
        transparent: false,
        text: '',
    });

    const showSpinner = (text, component = false, transparent = true) => {
        console.log(text);
        setSpinnerConfig({
            visible: true,
            transparent,
            text,
            component,
        });
    }

    const hideSpinner = () => {
        setSpinnerConfig({
            visible: false,
            transparent: false,
        });
    }

    return (
        <SpinnerManager.Provider value={{
            showSpinner,
            hideSpinner,
            spinnerIsVisible: spinnerConfig.visible
        }}>
            {children}
            {
                spinnerConfig.visible &&
                <div id="spinner-paper">
                    <RingLoader
                        color={'#fff'}
                        loading={true}
                        size={100}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    {spinnerConfig.text.length > 0 && <Typography variant="h5" sx={{mt: 10}} color='#fff'>
                        {spinnerConfig.text}
                    </Typography>}
                    {spinnerConfig.component}
                </div>
            }
        </SpinnerManager.Provider>
    )
}