import React from 'react';
import { Navigate, Outlet, useRoutes } from "react-router"

import { useAuth } from './contexts/auth';
import Spaces from './pages/Space';
import SetsPage from './pages/Lists';
import SignInSide from './pages/Login';
import SignUp from './pages/SignUp';
import { ImportLinkPage } from './pages/ImportLink';
import  MyAccount from './pages/MyAccount';
import { TestPage } from './pages/Test';



export function Routes() {
    const auth = useAuth();
    return useRoutes(
        !auth.isAuthenticated ? [
            {
                path: '/',
                element: <Outlet/>,
                children: [
                    {
                        path: '/*',
                        element: <Navigate
                            to={`/login?next=${btoa(window.location.pathname + window.location.search)}`}
                        />,
                        
                    },
                    {
                        path: '/',
                        element: <Navigate to="/login"/>
                    },
                    {
                        path: '/login',
                        element: <SignInSide />
                    },
                    {
                        path: '/cadastro',
                        element: <SignUp />
                    }
                ]
            }
        ] : [
            {
                path: '/',
                element: <Outlet/>,
                children: [
                    {
                        path: '/meus-espacos',
                        element: <Spaces />,
                    },
                    {
                        path: '/meus-espacos/:spaceId',
                        element: <Spaces />
                    },
                    {
                        path: '/listas/:spaceId/:setId',
                        element: <SetsPage />
                    },
                    {
                        path: '/links/import',
                        element: <ImportLinkPage />
                    },
                    {
                        path: '/minha-conta',
                        element: <MyAccount />
                    },
                    {
                        path: '/test',
                        element: <TestPage />
                    },
                    {
                        path: '/',
                        element: <Navigate to="/meus-espacos"/>
                    },
                    {
                        path: '/*',
                        element: <Navigate to="/meus-espacos"/>
                    },
                ]
            }
        ]
    );
}
