import { Button, Divider, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';

import { useEffect, useMemo, useState } from 'react';
import { useNotifications } from '../../../contexts/notification';
import { useSpaces } from '../../../contexts/spaces';
import { SchedulesService } from '../../../services/schedules';
import HandleSchedules from './handle';
import { hashPath } from '../../../constants';

const Weekdays = [
	'domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'
];

export const SchedulesComponent = ({ schedules, ids, selectedSet }) => {
	const location = useLocation();
    const navigate = useNavigate();
    const [hash, hashTargetId] = useMemo(() => {
        const splited = location.hash?.split('?');
        return  [splited[0] ?? '', splited[1] ?? null];
    });

    const { 
        showDialog,
        askUserPermission,
        registerPushNotification,
     } = useNotifications();
     const { updateSchedule } = useSpaces();


	const [handleSchedules, setHandleSchedules] = useState({
		open: false,
		onClose: () => { },
		schedule: {},
	});

	const closeHandleSchedules = () => {
        if (Object.values(hashPath.schedule).includes(hash)) {
            navigate(location.pathname);
        } else {
            setHandleSchedules({
                open: false,
                onClose: closeHandleSchedules,
                schedule: {},
            });
            // if (Notification.permission !== "granted") {
            //     showDialog({
            //         title: `Notificações para o bem`,
            //         message: 'Precisamos que você permita as notificações para conseguirmos te lembrar nesse dia e horário. Não se preocupe, não enviaremos nenhuma notificação que você não tiver ativado. =D',
            //         mode: 'ok',
            //         sendAction: async () => {
            //             await askUserPermission();
            //             await registerPushNotification();
            //         }
            //     });
            // }
        }
	}

    useEffect(() => {
        if (hash === hashPath.schedule.create) {
            if (hashTargetId === ids.habitId.toString()) {
                setHandleSchedules({
                    open: true,
                    onClose: closeHandleSchedules,
                    schedule: {
                        pushNotification: true,
                        enabled: true,
                        recurrent: true,
                        ...ids,
                    },
                });
            }
        } else if (hash === hashPath.schedule.update) {
            const schedule = schedules.find(s => {
                console.log(s.id.toString(), hashTargetId);
                return s.id.toString() === hashTargetId
            })
            if (schedule) {
                setHandleSchedules({
                    open: true,
                    onClose: closeHandleSchedules,
                    schedule: {
                        ...schedule,
                        ...ids,
                    },
                });
            }
        } else {
            closeHandleSchedules();
        }
    }, [ location, hash, hashTargetId ]);

    return (
        <Box>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h5" component="div">
                        Alarmes
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1, width: '40%', mr: 3 }} />
                    <Grid container spacing={5}>
                        {schedules?.map((schedule, index) => {

                            const _wd = Object.keys(schedule.weekdays);

                            const _wdPrs = {
                                '1,2,3,4,5': 'em todos os dias úteis',
                                '0,6': 'nos fins de semana',
                                '0,1,2,3,4,5,6': 'todos os dias'
                            }

                            const weekdays = _wd.map(w => Weekdays[w]);
                            const weekdaysResume = _wdPrs[_wd.join(',')];

                            return (
                                <Grid item key={`${schedule.id}-${index}`} xs={12} sm={6} md={4}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography>
                                            </Typography>
                                            <Typography>
                                                {schedule.email && 'E-mail'}
                                                {(schedule.mail && schedule.pushNotification) ? ' e notificação' : (
                                                    schedule.pushNotification ? 'Notificação' : ''
                                                )} {weekdaysResume ?? `no${weekdays.length > 1 ? 's' : ''} dia${weekdays.length > 1 ? 's' : ''} de ${weekdays.join(', ')}`}
                                            </Typography>
                                            <FormGroup>
                                                <FormControlLabel control={
                                                    <Switch checked={schedule.enabled} onChange={() => {
                                                        updateSchedule('update', {
                                                            ...schedule,
                                                            ...ids,
                                                            enabled: !schedule.enabled,
                                                            id: schedule.id,
                                                        });
                                                        SchedulesService.updateOrCreate({
                                                            enabled: !schedule.enabled,
                                                            id: schedule.id,
                                                        });
                                                        if (!schedule.enabled && Notification.permission !== "granted") {
                                                            showDialog({
                                                                callerId: 'updateEnableSchedule',
                                                                title: `Notificações para o bem`,
                                                                message: 'Precisamos que você permita as notificações para conseguirmos te lembrar nesse dia e horário. Não se preocupe, não enviaremos nenhuma notificação que você não tiver ativado. =D',
                                                                mode: 'ok',
                                                                sendAction: async () => {
                                                                    await askUserPermission();
                                                                    await registerPushNotification();
                                                                }
                                                            });
                                                        }

                                                    }} />
                                                } label={schedule.enabled ? 'Ativada' : 'Desativada'} />
                                            </FormGroup>
                                            <Button
                                                onClick={() => {
                                                    navigate(`${hashPath.schedule.update}?${schedule.id}`);
                                                }}
                                                variant={'text'}
                                                color={'secondary'}
                                            >horários e detalhes</Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
                <CardActions sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2
                }}>
                    <Button
                        startIcon={<AlarmAddIcon />}
                        size='medium'
                        onClick={() => {
                            navigate(`${hashPath.schedule.create}?${ids.habitId}`);
                        }}
                        variant={'contained'}
                    >Novo alarme</Button>
                </CardActions>
            </Card>
			<HandleSchedules {...handleSchedules} />
        </Box>
    );
}
