import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMemo, useState } from 'react';
import { useSpaces } from '../../contexts/spaces';
import { useNotifications } from '../../contexts/notification';
import { HabitsService } from '../../services/habits';


export default function HandleHabits(props) {
    const { showSnack } = useNotifications();
    const { updateHabit } = useSpaces();
    const [ disabled, setDisabled ] = useState(false);
    const habit = useMemo(() => props.habit);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        setDisabled(true);
        const data = new FormData(e.currentTarget);
        try {
            const [ name, description ] = [
                data.get('name'),
                data.get('description'),
            ];
            const response = await HabitsService.updateOrCreate({
                name, description, id: habit.id > 0 ? habit.id : undefined,
                setId: habit.setId,
            });
            updateHabit(habit.id > 0 ? 'update' : 'create', {
                ...habit,
                schedules: habit.schedules ?? [],
                name, description,
                id: response.data.id ?? habit.id,
                createdAt: new Date(),
                image: habit.image ?? 'random'
            });
            // response.data.id
            props.onClose();
        } catch(err) {
            showSnack(err.message, 'error');
        } finally {
            setDisabled(false);
        }
    }

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <form onSubmit={handleSubmit}>
            <DialogTitle>{!habit?.id ? 'Criar' : 'Modificar'} hábito</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome"
                    fullWidth
                    name={'name'}
                    defaultValue={habit?.name ?? ''}
                    required
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Descrição"
                    fullWidth
                    name={'description'}
                    defaultValue={habit?.description ?? ''}
                />
            </DialogContent>
            <DialogActions sx={{justifyContent:'space-between'}}>
                <Button onClick={props.onClose}>Cancelar</Button>
                <Button type='submit' disabled={disabled} variant={'contained'}>Confirmar</Button>
            </DialogActions>
            </form>
        </Dialog>
    );
}