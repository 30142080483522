import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import { Button, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { useNavigate, useLocation } from 'react-router';

import { useSpaces } from '../../contexts/spaces';
import { RANDOM_IMAGE_URL, hashPath } from '../../constants';
import { MyHeader } from '../../components/Header';
import HandleSpaces from './handleSpace';
import { useEffect, useState, useMemo } from 'react';
import HandleSets from '../Lists/handleSets';
import { ShareBox } from '../../components/ShareBox';
import { MyFooter } from '../../components/Footer';


export default function Spaces(props) {
	const navigate = useNavigate();
	const location = useLocation();
    const hash = useMemo(() => location.hash ?? '');

	const [ sharedObject, setSharedObject ] = useState(null);
	const { selectedSpace: space, spaces } = useSpaces();
    const [ handleSpaces, setHandleSpaces ] = useState({
        open: false,
        onClose: () => {},
        space: {},
    });
    const closeHandleSpaces = () => {
        setHandleSpaces({
            open: false,
            onClose: closeHandleSpaces,
            space: {},
        });
    }
    const [ handleSets, setHandleSets ] = useState({
        open: false,
        onClose: () => {},
        set: {},
    });
    const closeHandleSets = () => {
		if (hash === hashPath.sets.create) {
			navigate(location.hashPath);
		} else {
			setHandleSets({
				open: false,
				onClose: closeHandleSets,
				set: {},
			});
		}
    }

	useEffect(() => {
		if (hash === hashPath.sets.create) {
			setHandleSets({
				open: true,
				onClose: closeHandleSets,
				set: {
					spaceId: space.id
				},
			});
		} else {
			closeHandleSets();
		}
	}, [ location, hash, space ]);

	return (
		<>
			<CssBaseline />
			<MyHeader title={'Meus Espaços'} {...props} />
			<main>
				<Box
					sx={{
						bgcolor: 'background.paper',
						pt: 10,
						pb: 6,
					}}
				>
					<Paper
						sx={{
							position: 'relative',
							backgroundColor: 'grey.800',
							color: '#fff',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							backgroundImage: `url(${space.image === 'random' ? RANDOM_IMAGE_URL : space.image})`,
						}}
					>
						<Box sx={{
							backgroundColor: 'rgba(0,0,0,.3)',
							position: 'absolute',
							top: 0,
							bottom: 0,
							right: 0,
							left: 0,
						}} />
						<Container maxWidth="sm" sx={{ position: 'relative', py: 5 }}>
							<Typography
								component="h1"
								variant="h2"
								align="center"
								gutterBottom
							>
								{space.name}
							</Typography>
							<Typography variant="h5" align="center" paragraph>
								<div className="content" dangerouslySetInnerHTML={{
									__html: space.description
								}} />
							</Typography>
							<Stack
								sx={{ pt: 4 }}
								direction="row"
								spacing={2}
								justifyContent="center"
							>
								<Tooltip title='Compartilhar espaço'>
									<Button
										variant='contained'
										size={'small'}
										onClick={() => {
											setSharedObject({
												title: space.name,
												spaceId: space.id,
												kind: 'space'
											});
										}}
									>
										<ShareIcon />
									</Button>
								</Tooltip>
								<Tooltip title='Modificar espaço'>
									<Button onClick={() => {
										navigate(hashPath.spaces.update);
									}} color={'secondary'} variant='contained' size={'small'}><EditIcon /></Button>
								</Tooltip>
								<Tooltip title='Deletar espaço'>
									<Button disabled={spaces.length <= 1} onClick={() => {
										navigate(hashPath.spaces.delete);
									}} color={'error'} variant='contained' size={'small'}><DeleteIcon /></Button>
								</Tooltip>
							</Stack>
						</Container>
					</Paper>
				</Box>
				<Container sx={{ py: 2 }} maxWidth="md">
					<Grid container spacing={4}>
						{space.sets.map((card) => (
							<Grid item key={`${card.id}-${card.name}`} xs={12} sm={6} md={4}>
								<Card
									sx={{
										height: '100%',
										display: 'flex',
										flexDirection: 'column',
										cursor: 'pointer',
									}}
									onClick={() => {
										navigate(`/listas/${space.id}/${card.id}`);
									}}
								>
									<CardMedia
										component="div"
										sx={{
											// 16:9
											pt: '56.25%',
										}}
										image={card.image === 'random' ? RANDOM_IMAGE_URL : card.image}
									/>
									<CardContent sx={{ flexGrow: 1 }}>
										<Typography gutterBottom variant="h5" component="h2">
											{card.name}
										</Typography>
										<Typography>
											{card.description}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
					<Grid container spacing={4} sx={{
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<Grid item md={12} sx={{
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							mt: 3
						}}>
							<Button
								variant='contained'
								startIcon={<PlaylistAddIcon />}
								onClick={() => {
									navigate(hashPath.sets.create);
								}}
							>Nova lista</Button>
						</Grid>
					</Grid>
				</Container>
				<MyFooter />
			</main>
			<HandleSpaces {...handleSpaces} />
			<HandleSets {...handleSets} />
			<ShareBox objectToShare={sharedObject} onClose={() => {setSharedObject(null)}} />
		</>
	);
}