import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt-br';

import { ThemeProvider } from './contexts/theme';
import { AuthProvider } from './contexts/auth';
import { SpacesProvider } from './contexts/spaces';
import { Routes } from './routes';

import reportWebVitals from './reportWebVitals';
import { NotificationsProvider } from './contexts/notification';
import './index.css';
import { SnackbarProvider } from 'notistack';
import { LoadSpinnerProvider } from './contexts/LoadSpinner';

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
navigator.serviceWorker?.register(swUrl);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<SnackbarProvider maxSnack={3}>
			<ThemeProvider>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
					<LoadSpinnerProvider>
						<NotificationsProvider>
							<AuthProvider>
								<BrowserRouter>
									<SpacesProvider>
										<Routes />
									</SpacesProvider>
								</BrowserRouter>
							</AuthProvider>
						</NotificationsProvider>
					</LoadSpinnerProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</SnackbarProvider>
	</React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
