import { useState } from 'react';
import styles from './styles.module.css';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { AddPhotoAlternate, Download, HighlightOff } from '@mui/icons-material';

export function UploadImageFile({ hero, setHero }) {
    const [dragActive, setDragActive] = useState(false);
    const [formatInvalid, setFormatInvalid] = useState(false);

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === "dragenter" || e.type === "dragover") && !dragActive) {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (!e.dataTransfer.files[0].type.includes("image")) {
                setHero(null)
                setFormatInvalid(true)
                setTimeout(() => {
                    setFormatInvalid(false)
                }, 3000);
            } else {
                setHero(e.dataTransfer.files[0])
            }
        }
    };

    const handleClick = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files && e.target.files[0]) {
            setHero(e.target.files[0])
        }
    };

    return (
        <>
            <div
                className={`${styles.uploadContainer} ${dragActive ? styles.dragActive : ""}`}
                onDragEnter={handleDrag}
                onDrop={handleDrop}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
            >
                <label htmlFor="hero">
                    {hero ?
                        <img src={typeof hero === "string" ? hero : URL.createObjectURL(hero)} className={styles.hero} />
                        :
                        <>
                            {formatInvalid ? <HighlightOff color='error' fontSize='large' /> : dragActive ? <Download color='disabled' fontSize={"large"} /> : <AddPhotoAlternate color='disabled' fontSize={"large"} />}
                            <Typography sx={{p: '5px', textAlign: 'center'}} color={formatInvalid ? "red" : "GrayText"} variant='overline'> {formatInvalid ? "Formato inválido" : dragActive ? "Solte para anexar" : "Arraste e solte ou clique para selecionar"}</Typography>
                        </>}
                </label>
                <input id="hero" type="file" accept="image/*" hidden onChange={handleClick} />
            </div>
            {hero &&
                <div className={styles.removeImage}>
                    <Typography>{hero.name ?? ""}</Typography>
                    <Tooltip title="Remover imagem" placement='right'>
                        <IconButton onClick={() => setHero(null)}>
                            <HighlightOff color='error' />
                        </IconButton>
                    </Tooltip>
                </div>}

        </>
    )
}